<script>
export default {
  name: "AppButtonCollapse"
}
</script>

<script setup>
import { AppButton } from ".."

import { ICON } from "@/lib/constants"

defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  visibleText: {
    type: String,
    default: null
  },
  nonVisibleText: {
    type: String,
    default: null
  }
})
defineEmits(["updateVisibility"])
</script>

<template>
  <div>
    <AppButton
      type="button"
      :icon="visible ? ICON.MINUS : ICON.PLUS"
      icon-size="16"
      :text="visible && visibleText ? visibleText : !visible && nonVisibleText ? nonVisibleText : null"
      @on-clicked="$emit('updateVisibility')"
    />
  </div>
</template>
