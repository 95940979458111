import { createRouter, createWebHistory } from "vue-router"

import adminRouter from "@/features/admin/router"
import authRouter from "@/features/auth/router"
import errorPagesRouter from "@/features/error/router"
import providersRouter from "@/features/providers/router"
import { authGuard, loginGuard } from "@/guards"
import { IS_ADMIN_ENTITY, IS_PROVIDER_ENTITY, ROUTE_NAMES, ROUTE_PATHS, PAGE_URLS } from "@/lib/constants"
import { tc } from "@/lib/services"

// Determine the home URL redirection based on entity
const HOME_URL_REDIRECT = IS_ADMIN_ENTITY
  ? PAGE_URLS.ADMIN.ROUTE_PANEL : IS_PROVIDER_ENTITY
    ? PAGE_URLS.PROVIDERS.HOME : ROUTE_PATHS.ERRORS.NOT_FOUND


// Redirection configuration for the not found page
const NOT_FOUND_REDIRECTION = {
  path: ROUTE_PATHS.ERRORS.NOT_FOUND.PATH,
  redirect: ROUTE_PATHS.ERRORS.NOT_FOUND.BASE
}

const routes = [
  // Home route
  {
    path: ROUTE_PATHS.HOME,
    name: ROUTE_NAMES.HOME,
    redirect: HOME_URL_REDIRECT
  },

  // Auth Routes
  {
    path: ROUTE_PATHS.AUTH.BASE,
    children: [...authRouter],
    meta: {
      title: tc("PageTitle.Auth"),
      isAuth: true
    }
  },

  // Admin routes
  {
    path: ROUTE_PATHS.ADMIN.BASE,
    children: IS_ADMIN_ENTITY ? [...adminRouter] : [{ ...NOT_FOUND_REDIRECTION }],
    meta: {
      title: tc("PageTitle.Admin"),
      requiresAuth: true
    }
  },

  // Provider Routes
  {
    path: ROUTE_PATHS.PROVIDERS.BASE,
    children: IS_PROVIDER_ENTITY ? [...providersRouter] : [{ ...NOT_FOUND_REDIRECTION }],
    meta: {
      title: tc("PageTitle.Providers"),
      requiresAuth: true
    }
  },

  // Error Pages
  ...errorPagesRouter
]

// Init global router
const router = createRouter({
  history: createWebHistory(),
  routes
})


// Change document title if route has meta params
router.beforeEach((to, _, next) => {
  const { requiresAuth, isAuth, role } = to.meta

  // Check Protected routes
  if (requiresAuth) {
    authGuard(next, role)
    return
  }

  // Check Auth on Auth pages
  if (isAuth) {
    loginGuard(next)
    return
  }

  next()
})

// After earch route verify token if exists
router.afterEach(to => {
  const { title } = to.meta

  // Set Document Title
  if (title) document.title = title
})


export default router
