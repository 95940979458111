<script>
export default {
  name: "AppScroll"
}
</script>

<script setup>
import { computed } from "vue"

const props = defineProps({
  direction: {
    type: String,
    default: "horizontal",
    validator: value => ["horizontal", "vertical"].includes(value)
  },
  maxWidthStyle: String
})


const directionClass = computed(() => ({
  "horizontal-scroll": props.direction === "horizontal",
  "vertical-scroll": props.direction === "vertical"
}))
</script>

<template>
  <div>
    <div
      class="scroll-layout"
      :class="directionClass"
    >
      <div :style="{ 'max-width': maxWidthStyle }">
        <slot />
      </div>
    </div>
  </div>
</template>

<style scoped>
 .horizontal-scroll {
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
  padding: 16px 0 16px 0;
  box-sizing: border-box;
}

.vertical-scroll {
  overflow-y: auto;
  height: 100%;
  padding: 16px;
  box-sizing: border-box;
  border: 1px solid #ccc;
}


.scroll-layout::-webkit-scrollbar {
    width: 1em;
    height: 2px;
    cursor: pointer !important;
}
</style>
