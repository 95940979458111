<script>
export default {
  name: "AppErrorPage"
}
</script>

<script setup>
import { AppFlex } from "@/components/layouts"
import { AppLinkButton, AppText } from "@/components/ui"
import { ICON, ROUTE_PATHS } from "@/lib/constants"

defineProps({
  title: String,
  subtitle: String,
  error: String
})
</script>

<template>
  <AppFlex
    justify="center"
    align="center"
    direction="col"
    gap="3"
    class="h-80-vh p-3"
  >
    <!-- Title -->
    <AppText weight="bold" class="title w-sm-100">
      {{ title }}
    </AppText>

    <!-- Subtitle -->
    <AppText type="muted" size="4" class="subtitle w-sm-100">
      {{ subtitle }}
    </AppText>

    <!-- Error text -->
    <AppText type="danger" class="title" weight="bold">
      {{ error }}
    </AppText>

    <AppLinkButton
      :to="ROUTE_PATHS.HOME"
      :icon="ICON.ARROW_LEFT"
      :text="$t('General.Back')"
    />
  </AppFlex>
</template>

<style scoped lang="scss">
.title {
  font-size: 3.75rem;
  text-align: center;

  @media (max-width: $screen-md-max) {
    font-size: 2.75rem;
  }
}

.subtitle {
  width: 50%;
  text-align: center;
}
</style>