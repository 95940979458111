import axios from "axios"

import { useLocalStorage } from "@/composables"
import { ENDPOINTS, UI } from "@/lib/constants"
import { tc } from "@/lib/services"
import { createToastFunctions } from "@/plugins/toast"
import { resetAllPiniaStores, useAuthStore } from "@/stores"

// Function to verify the token's validity
export const verifyTokenGuard = () => {
  // Access the authentication store
  const auth = useAuthStore()

  // Access local storage methods for user and token removal
  const { removeItem: removeUser } = useLocalStorage(UI.LOCALSTORAGE.CURRENT_USER_KEY)
  const { removeItem: removeToken } = useLocalStorage(UI.LOCALSTORAGE.TOKEN_KEY)

  // Send a request to verify the token
  axios.get(ENDPOINTS.AUTH.VERIFY_TOKEN)
    .catch(error => {
      // If the response status is 401 (Unauthorized)
      if (error?.response?.status === 401) {
        // Logout the user
        auth.logout()

        // Remove user and token from local storage
        removeUser()
        removeToken()

        // Reset all Pinia stores
        resetAllPiniaStores()

        // Display an error message
        const { toast } = createToastFunctions()
        toast({
          title: tc("General.Error"),
          message: tc("Auth.TokenError"),
          type: "danger",
          timeout: 5000
        })
      }
    })
}
