export const SEED = {
  SELECT_OPTIONS: [
    { value: "value1", label: "Option 1" },
    { value: "value2", label: "Option 2" },
    { value: "value3", label: "Option 3" }
  ],

  Positions: [
    {
      DriverID: 12345,
      Latitude: 41.6233292,
      Longitude: -4.704413,
      CreatedAt: "2024-07-18 07:26:49"
    },
    {
      DriverID: 12345,
      Latitude: 41.6233321,
      Longitude: -4.7044349,
      CreatedAt: "2024-07-18 07:26:52"
    },
    {
      DriverID: 12345,
      Latitude: 41.6230351,
      Longitude: -4.7042092,
      CreatedAt: "2024-07-18 07:29:56"
    },
    {
      DriverID: 12345,
      Latitude: 41.6230413,
      Longitude: -4.7042178,
      CreatedAt: "2024-07-18 07:29:57"
    },
    {
      DriverID: 12345,
      Latitude: 41.6242225,
      Longitude: -4.7050437,
      CreatedAt: "2024-07-18 07:34:56"
    },
    {
      DriverID: 12345,
      Latitude: 41.6490837,
      Longitude: -4.6716401,
      CreatedAt: "2024-07-18 07:39:56"
    },
    {
      DriverID: 12345,
      Latitude: 41.7160706,
      Longitude: -4.6728205,
      CreatedAt: "2024-07-18 07:44:56"
    },
    {
      DriverID: 12345,
      Latitude: 41.7654127,
      Longitude: -4.6269546,
      CreatedAt: "2024-07-18 07:49:56"
    },
    {
      DriverID: 12345,
      Latitude: 41.8211917,
      Longitude: -4.5780679,
      CreatedAt: "2024-07-18 07:54:56"
    },
    {
      DriverID: 12345,
      Latitude: 41.8808548,
      Longitude: -4.5386944,
      CreatedAt: "2024-07-18 07:59:57"
    },
    {
      DriverID: 12345,
      Latitude: 41.9391861,
      Longitude: -4.5029573,
      CreatedAt: "2024-07-18 08:04:56"
    },
    {
      DriverID: 12345,
      Latitude: 41.9936779,
      Longitude: -4.5088051,
      CreatedAt: "2024-07-18 08:09:56"
    },
    {
      DriverID: 12345,
      Latitude: 42.0028438,
      Longitude: -4.5101196,
      CreatedAt: "2024-07-18 08:14:57"
    }
  ],

  /* ------------------------------ Matrix ------------------------------------------ */
  MATRIX: [
    {
      name: "Plaza1",
      distances: [
        { name: "Plaza1", distance: null },
        { name: "Plaza2", distance: "5" },
        { name: "Plaza3", distance: "10" }
        // ... más distancias
      ]
    },
    {
      name: "Plaza2",
      distances: [
        { name: "Plaza1", distance: "5" },
        { name: "Plaza2", distance: null },
        { name: "Plaza3", distance: "0" }
      ]
    },
    {
      name: "Plaza3",
      distances: [
        { name: "Plaza1", distance: "5" },
        { name: "Plaza2", distance: "0" },
        { name: "Plaza3", distance: null }
      ]
    }
  ]
}

Object.freeze(SEED)