import { ref, computed } from "vue"

import { ICON, UI } from "@/lib/constants"

export const useAppToast = props => {
  const { close, timeout, type } = props

  // Refs
  let innerTimeout = ref(null)
  let progress = ref(0)
  const isClosing = ref(false)

  // Clear the timeout if it exists
  const toClearTimeout = () => {
    if (!innerTimeout.value) return
    try {
      clearTimeout(innerTimeout.value)
    } catch (e) {
      console.error(e)
    }
  }

  // Handle the close event with a timeout
  const handleBeforeClose = () => {
    innerTimeout.value = setTimeout(() => {
      isClosing.value = true
      setTimeout(() => close(), 500)
    }, timeout)
  }

  // Start the progress bar animation
  const startProgress = () => {
    const endTime = Date.now() + timeout
    const updateInterval = 100
    const duration = timeout

    // Update the progress value based on the remaining time
    const updateProgress = () => {
      const currentTime = Date.now()
      const remainingTime = endTime - currentTime
      progress.value = Math.max(0, (remainingTime / duration) * 100)
      if (remainingTime > 0) {
        setTimeout(updateProgress, updateInterval)
      }
    }

    updateProgress()
  }

  // Determine the toast type and its associated style
  const toastType = {
    success: { color: UI.COLORS.SUCCESS, icon: ICON.CHECK },
    danger: { color: UI.COLORS.DANGER, icon: ICON.EXCLAMATION_CIRCLE },
    info: { color: UI.COLORS.PRIMARY, icon: ICON.INFORMATION_CIRCLE }
  }[type]

  // Compute the toast style based on its type
  const toastStyle = computed(() => `border-left: 25px solid ${toastType.color}`)

  // Compute the progress bar style based on the progress value
  const iconStyles = computed(() => {
    return {
      "background-color": toastType.color
    }
  })

  // Compute the progress bar style based on the progress value
  const progressStyle = computed(() => {
    return {
      "width": `${progress.value}%`,
      "background-color": toastType.color
    }
  })

  return {
    // States
    iconStyles,
    isClosing,
    progressStyle,
    toastStyle,
    toastType,

    // Functions
    handleBeforeClose,
    startProgress,
    toClearTimeout
  }
}
