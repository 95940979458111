import * as yup from "yup"

import { REGULAR_EXPRESIONS } from "@/lib/constants"
import { tc } from "@/lib/services"

export default {
  // Form Data
  data: {
    PasswordCurrent: "",
    PasswordNew: "",
    PasswordConfirm: ""
  },

  // Form Validations
  schema: yup.object().shape({

    /* Old Password */
    PasswordCurrent: yup.string().required(tc("Validations.Required")),

    /* New Password */
    PasswordNew: yup.string()
      .matches(REGULAR_EXPRESIONS.PASSWORD, tc("Validations.InvalidPassword"))
      .required(tc("Validations.Required")),

    /* Password Confirm */
    PasswordConfirm: yup.string()
      .oneOf([yup.ref("PasswordNew"), null], tc("Validations.PasswordConfirm"))
      .required(tc("Validations.Required"))
  })
}