<script>
export default {
  name: "AppTableHead"
}
</script>

<script setup>
import { ref, watch } from "vue"

import AppTableColIcon from "./AppTableColIcon.vue"

import { AppText } from "@/components/ui"

const props = defineProps({
  columns: {
    type: Array,
    required: true
  }
})

const columnsList = ref(props.columns)

const emit = defineEmits(["updateSorter"])

const handleSortableClick = column => {
  if (!column?.sortable) return
  emit("updateSorter", column)
}

watch(() => props.columns, newColumns => columnsList.value = newColumns)
</script>

<template>
  <thead class="app-table-head">
    <!-- Table Cols -->
    <th
      v-for="(column, columnIndex) in columnsList"
      :key="columnIndex"
      :class="[
        'app-table-th',
        { 'text-center' : column?.centered}
      ]"
    >
      <div @click="handleSortableClick(column)">
        <!-- Text -->
        <AppText
          weight="bold"
          class="app-table-th-text"
        >
          {{ column?.text }}
        </AppText>

        <!-- Sortable -->
        <AppTableColIcon
          v-if="column?.sortable"
          :direction="column?.direction"
        />
      </div>
    </th>
  </thead>
</template>

<style
  src="../../styles/app-table.styles.scss"
  lang="scss"
  scoped
/>