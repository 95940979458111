import { ref } from "vue"

import { useCrypto } from "./useCrypto"

export const useLocalStorage = (key, defaultValue = null) => {
  // Get encryption and decryption functions from useCrypto
  const { encrypt, decrypt, encryptKey } = useCrypto()

  // Encrypt the key for local storage
  const encryptedKey = ref(encryptKey(key))

  // Initialize the value by decrypting the stored item or using the default value
  const value = ref(
    decrypt(localStorage.getItem(encryptedKey.value) || "") ?? defaultValue
  )

  // Set a new encrypted value to local storage and update the value reference
  const setItem = newValue => {
    const encryptedNewValue = encrypt(newValue)
    localStorage.setItem(encryptedKey.value, encryptedNewValue)
    value.value = newValue
  }

  // Remove the encrypted value from local storage and reset the value reference to default
  const removeItem = () => {
    localStorage.removeItem(encryptedKey.value)
    value.value = defaultValue
  }

  return {
    value,
    setItem,
    removeItem
  }
}
