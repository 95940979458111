import { tc } from "@/lib/services"

export const UI = {
  // Localstorage Keys
  LOCALSTORAGE: {
    REMEMBER_LOGIN_KEY:  process.env.VUE_APP_REMEMBER_KEY,
    TOKEN_KEY:  process.env.VUE_APP_TOKEN_KEY,
    CURRENT_USER_KEY: process.env.VUE_APP_USER_KEY
  },

  // Header
  HEADER_WIDTH: {
    OPEN: "245",
    CLOSED: "131"
  },

  // Auth containers
  AUTH_WIDTH: {
    LOGIN: "720",
    FORGOT_PASSWORD: "950"
  },

  // Images
  IMAGE_PATHS: {
    ALERT: "/images/alert.svg",
    CRANE: "/images/crane.svg",
    EMPTY: "/images/empty.svg",
    FORGOT_PASSWORD: "/images/forgot-password-success.svg",
    LOGO: "/images/logo.svg",
    POINT: "/images/point.svg",
    SIGNS:  "/images/signs.svg",
    WORKER: "/images/worker.svg"
  },

  // Colors for use in JS files (see variables.scss)
  COLORS: {
    PRIMARY: "#061ab1",
    DANGER: "#f34141",
    SUCCESS: "#c0e2ad",
    GREY_200: "#e5e7ea",
    GREY_300: "#CED2D6"
  },

  // Local Selector Options
  SELECTOR_OPTIONS: {
    BOOLEAN: [
      { Value: true, Name: tc("General.Yes") },
      { Value: false, Name: tc("General.No") }
    ],
    SORTERS: [
      { key: "asc", text: tc("General.Asc") },
      { key: "desc", text: tc("General.Desc") }
    ]
  }
}

Object.freeze(UI)