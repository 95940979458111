import { ROUTE_PATHS } from "./route-paths.constants"

// Only for security redirections
export const PAGE_URLS = {
  AUTH: {
    LOGIN: `${ROUTE_PATHS.AUTH.BASE}/${ROUTE_PATHS.AUTH.LOGIN}`
  },
  ERROR: {
    FORBIDDEN: ROUTE_PATHS.ERRORS.FORBIDDEN
  },
  ADMIN: {
    ROUTE_PANEL: `${ROUTE_PATHS.ADMIN.BASE}/${ROUTE_PATHS.ADMIN.ROUTES.PANEL}`
  },
  PROVIDERS: {
    HOME: `${ROUTE_PATHS.PROVIDERS.BASE}/${ROUTE_PATHS.PROVIDERS.CONTRACTED_ROUTES}`
  }
}

Object.freeze(PAGE_URLS)