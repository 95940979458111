export default [
  // General data
  {
    Name: "POSITION_MINUTES",
    Value: null,
    AvoidAlertRepeat: false,
    PrioritizeAlertMinutes: -1
  },

  {
    Name: "PAUSE_LONG_MINUTES",
    Value: null,
    AvoidAlertRepeat: true,
    PrioritizeAlertMinutes: -1
  },


  // Alerts data
  {
    Name: "PAUSE_ALERT_MINUTES",
    Value: -1,
    AvoidAlertRepeat: false,
    PrioritizeAlertMinutes: -1
  },

  {
    Name: "ROUTEPOI_EXIT_DELAY_MINUTES",
    Value: -1,
    AvoidAlertRepeat: false,
    PrioritizeAlertMinutes: -1
  },

  {
    Name: "ROUTEPOI_ARRIVAL_DELAY_MINUTES",
    Value: -1,
    AvoidAlertRepeat: false,
    PrioritizeAlertMinutes: -1
  },

  {
    Name: "CONNECTION_LOST_MINUTES",
    Value: -1,
    AvoidAlertRepeat: false,
    PrioritizeAlertMinutes: -1
  },

  {
    Name: "ROUTE_ABANDONED_MINUTES",
    Value: -1,
    AvoidAlertRepeat: false,
    PrioritizeAlertMinutes: -1
  },

  {
    Name: "LOW_BATTERY_PERCENT",
    Value: -1,
    AvoidAlertRepeat: false,
    PrioritizeAlertMinutes: -1
  },

  {
    Name: "ROUTE_STOPPED_MINUTES",
    Value: -1,
    AvoidAlertRepeat: false,
    PrioritizeAlertMinutes: -1
  },

  {
    Name: "ROUTE_WITHOUT_DRIVER_MINUTES",
    Value: -1,
    AvoidAlertRepeat: false,
    PrioritizeAlertMinutes: -1
  },

  {
    Name: "ROUTE_WITHOUT_VEHICLE_MINUTES",
    Value: -1,
    AvoidAlertRepeat: false,
    PrioritizeAlertMinutes: -1
  }
]
