import { ref, computed } from "vue"

import { useAxios } from "@/composables"
import { METHODS } from "@/lib/constants"
import { tc } from "@/lib/services"

export const useAppAutoComplete = (props, emit) => {
  const { placeholder, url, responseKey, paramKey, selected, valueProperty, displayProperty } = props

  const { request } = useAxios()

  // Refs
  const searchText = ref("")
  const loading = ref(false)
  const selectedItem = ref(selected || null)
  const options = ref([])

  const customPlaceholder = computed(() => {
    return placeholder || tc("General.WriteHere")
  })

  // Handles the input change event and fetches data
  const handleInputChange = async value => {
    try {
      loading.value = true
      searchText.value = value

      const { data } = await request({
        url: `${url}?${paramKey}=${encodeURIComponent(value)}`,
        method: METHODS.GET
      })

      options.value = data?.response?.[responseKey] || []

      if (!value) emit("update:modelValue", null)
    } finally {
      loading.value = false
    }
  }

  // Handles the input click event to fetch data
  const handleInputClick = async () => {
    if (searchText.value && !options.value?.length !== 0) {
      await handleInputChange(searchText.value)
    }
  }

  // Handles item click event to update state
  const onItemClicked = item => {
    selectedItem.value = item?.[valueProperty]
    searchText.value = item?.[displayProperty]
    options.value = []
    emit("update:modelValue", item?.[valueProperty])
  }

  return {
    // States
    customPlaceholder,
    loading,
    options,
    searchText,
    selectedItem,

    // Functions
    handleInputChange,
    handleInputClick,
    onItemClicked
  }
}
