import * as yup from "yup"

import { REGULAR_EXPRESIONS } from "@/lib/constants"
import { tc } from "@/lib/services"

const commonValidations = {
  /* Name */
  Name: yup.string().required(tc("Validations.Required")),

  /* Email */
  Email: yup.string().email(tc("Validations.InvalidEmail")),

  /* Nic */
  Nic: yup.string(),

  /* Phone */
  Phone: yup.string()
    .required(tc("Validations.Required"))
    .transform(value => !value ? "" : value )
    .matches(REGULAR_EXPRESIONS.PROFILE_PHONE_NUMBER, tc("Validations.InvalidPhone"))
}

export default {
  // Form Data Create
  Name: "",
  Email: "",
  Nic: "",
  Phone: "",

  // Form Data
  data: {
    Name: "",
    Email: "",
    Nic: "",
    Phone: ""
  },

  // Form Validations
  schema: yup.object().shape({
    ...commonValidations
  })
}
