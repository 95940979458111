import { i18n } from "@/plugins"

export const changeI18nLanguage = newLanguageCode => {
  i18n.global.locale = newLanguageCode
}

/**
 * Function to access the translation function (t) from i18n outside of Vue files, such as columns and routers.
 * This allows for convenient translation usage in various parts of the application.
 * @param {string} key - The key to translate.
 * @returns {string} - The translated string.
 */
export const tc = i18n.global.t