import * as yup from "yup"

import { REGULAR_EXPRESIONS } from "@/lib/constants"
import { tc } from "@/lib/services"

export default {
  // Form Data
  data: {
    Email: "",
    Code: "",
    Password: "",
    PasswordConfirm: ""
  },

  // Form Validations
  schema: yup.object().shape({
    /* Email */
    Email: yup.string()
      .email(tc("Validations.InvalidEmail"))
      .required(tc("Validations.Required")),

    /* Code */
    Code: yup.string().required(tc("Validations.Required")),

    /* Password */
    Password: yup.string()
      .matches(REGULAR_EXPRESIONS.PASSWORD, tc("Validations.InvalidPassword"))
      .required(tc("Validations.Required")),

    /* Password Confirm */
    PasswordConfirm: yup.string()
      .oneOf([yup.ref("Password"), null], tc("Validations.PasswordConfirm"))
      .required(tc("Validations.Required"))
  })
}