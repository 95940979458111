<script>
export default {
  name: "AppHeaderNotificationsIcon"
}
</script>

<script setup>
import { ref } from "vue"

import { AppHeaderNotificationCard } from ".."

import { AppBadge, AppButton } from "@/components/ui"
import { useWindowSize } from "@/composables"
import { ICON } from "@/lib/constants"
import { useHeaderAlertStore, useUiStore } from "@/stores"

const notificationCardIsVisible = ref(false)
const ui = useUiStore()
const { isMobile } = useWindowSize()
const headerAlerts = useHeaderAlertStore()

const handleClickOutside = () => {
  if (!notificationCardIsVisible.value) return
  notificationCardIsVisible.value = false
}

const handleIconClick = () => {
  if (ui.sidebarIsOpen && isMobile.value) ui.toogleSidebar()
  notificationCardIsVisible.value = !notificationCardIsVisible.value
}
</script>

<template>
  <div v-click-outside="handleClickOutside">
    <!-- Button -->
    <AppButton
      type="button"
      variant="primary"
      icon-color="white"
      icon-variant="solid"
      :icon="ICON.BELL"
      class="position-relative"
      @on-clicked="handleIconClick"
    >
      <AppBadge
        v-if="headerAlerts.alertsCount"
        type="orange"
        class="position-absolute badge-icon"
        shape="circle"
        :text="headerAlerts.alertsCount"
      />
    </AppButton>

    <!-- Notification Card -->
    <AppHeaderNotificationCard
      v-if="notificationCardIsVisible"
      @closeCard="notificationCardIsVisible = false"
    />
  </div>
</template>

<style lang="scss" scoped>
.badge-icon {
    top: 5px;
    left: 32px;
    padding: 8px 5px 5px 5px;
}
</style>
