import L from "leaflet"
import "leaflet.markercluster"

import { mapIconManager } from "./map-icon.manager"
import { mapPopupManager } from "./map-popup.manager"

import { MAP } from "@/lib/constants"

class MarkerManager {
  constructor() {
    // Reference to the root HTML element
    this.root = document.documentElement
    this.currentMarkerGroup = null
    this.mapMarkers = []
    this.markerBounds = []
  }

  // Get a marker cluster group with specified options
  getMarkerGroup() {
    this.currentMarkerGroup = L.markerClusterGroup({
      // Define the function to create cluster icons
      iconCreateFunction: function(cluster) {
        return L.divIcon({
          html: `<div class='app-marker-cluster'>${cluster.getChildCount()}</div>`,
          className: "marker-cluster",
          iconSize: [56, 56]
        })
      },
      // Enable zoom to bounds when clicking on a cluster
      zoomToBoundsOnClick: true
    })

    return this.currentMarkerGroup
  }

  // Display a marker on the map with specified icon
  displayMarker(map, marker) {
    // Create Marker Instance
    const markerIcon = marker?.iconUrl ?
      mapIconManager.buildIcon(marker?.iconUrl):
      mapIconManager.getIcon(marker?.variant)

    const currentMarker = L.marker(
      [marker.lat, marker.lon],

      // Set marker icon
      {
        icon: markerIcon,
        zIndexOffset: -100 // Under the cluster group
      }
    )

    // Add marker to map
    currentMarker.addTo(map)

    if (marker?.popup) {
      // Show Popup if exists
      mapPopupManager.handleMarkerPopup({
        data: marker?.popup,
        marker: currentMarker
      })
      // Add hover class
      this.addClass(currentMarker, "hover-default")
    }

    // Add marker location to bounds array
    const measures = currentMarker.getLatLng()
    this.markerBounds.push([measures.lat, measures.lng])

    return currentMarker
  }


  // Handle mouse hover event on a marker
  onMouseHover(e) {
    e.target._icon.classList.add("marker-icon")

    const { x, y } = e.layerPoint

    this.root.style.setProperty("--position-x", x + "px")
    this.root.style.setProperty("--position-y", y + "px")
  }

  // Handle mouse out event from a marker
  onMouseOut(e) {
    e.target._icon.classList.remove("marker-icon")
    this.root.style.removeProperty("--position-x")
    this.root.style.removeProperty("--position-y")
  }

  // Add class to a marker
  addClass(marker, className) {
    marker._icon.classList.add(className)
  }

  // Delete All Group Parents
  clearLayers() {
    if (!this.currentMarkerGroup) return
    this.currentMarkerGroup.clearLayers()
  }

  // Change Icon Function
  changeMarkerIcon(marker, newIcon) {
    marker.setIcon(mapIconManager.getIcon(newIcon))
  }

  // Function to set a makers array
  setMapMarkers(makers) {
    this.mapMarkers = makers
  }

  // Function to get the location Marker
  getCurrentMarkerLocation() {
    const marker = this.mapMarkers?.find(marker => marker?.variant === MAP.ICON_VARIANTS.LOCATION)
    return !marker ? null : [marker?.lat, marker?.lon]
  }

  // Set default zoom and location
  setZoomBasedOnmarkers(map) {
    if (this.markerBounds?.length === 0) return
    const bounds = L.latLngBounds(this.markerBounds)
    map.fitBounds(bounds)
  }
}

export const markerManager = new MarkerManager()