<script>
export default {
  name: "AppButton",
  inheritAttrs: true
}
</script>

<script setup>
import { computed } from "vue"

import { AppIcon, AppSpinner, AppText } from ".."

import { AppFlex } from "@/components/layouts"

const props = defineProps({
  size: {
    type: String,
    default: "lg",
    validator: value => ["xl", "lg", "md", "sm"].includes(value)
  },
  text: {
    type: String,
    default: null
  },
  variant: {
    type: String,
    default: "warning",
    validator: value => [
      "primary", "secondary", "success", "danger",
      "warning", "info", "light", "dark", "link",
      "silver", "sunshine", "blush", "white"
    ].includes(value)
  },
  block: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  outline: {
    type: Boolean,
    default: false
  },
  icon: {
    type: String,
    default: null
  },
  centered: {
    type: Boolean,
    default: true
  },
  loading: {
    type: Boolean,
    default: false
  },
  uppercase: {
    type: Boolean,
    default: false
  },
  iconColor: {
    type: String,
    default: "primary"
  },
  iconSize: {
    type: String,
    default: null
  },
  iconVariant: {
    type: String,
    default: "outline"
  },
  textColor: {
    type: String,
    default: "primary"
  },
  textSize: {
    type: String,
    default: "6"
  },
  textClass: {
    type: String,
    default: null
  },
  reverse: {
    type: Boolean,
    default: false
  },
  white: {
    type: Boolean,
    default: false
  }
})

defineEmits(["on-clicked"])

const isIconButton = computed(() => props.icon && !props.text)

const iconSizeValue = computed(() => {
  if (props.iconSize) return props.iconSize
  return isIconButton.value ? "24" : "28"
})

const buttonClasses = computed(() => {
  const classes = ["btn"]

  classes.push(
    // Size
    ...([isIconButton.value ? "btn-md" : `btn-${props.size}`]),

    // Variant
    ...(props.variant && !props?.outline ? [`btn-${props.variant}`] : []),

    // Full With
    ...(props.block ? ["w-100"] : []),

    // Outline
    ...(props.outline ? [`btn-outline-${props.variant}`, "no-hover"] : []),

    // Uppercase
    ...(props.uppercase ? ["text-uppercase"] : []),

    // Remove underline decoration for link buttons
    ...(props.variant === "link" ? ["text-decoration-none"] : []),

    // Different hover for icon buttons
    ...(isIconButton.value ? ["round-hover-12 py-2 border-soft-brown"] : ["round-hover px-3"]),

    // White Background for un-active buttons
    ...(props.white ? ["bg-white"] : [])
  )

  return classes
})

// Manage Button Text Color
const textColorType = computed(() => {
  if (!["success", "secondary", "blush", "silver", "sunshine"].includes(props.variant)) {
    return props.textColor
  }
  return "black"
})
</script>

<template>
  <button
    :class="buttonClasses"
    :disabled="disabled"
    @click="$emit('on-clicked')"
  >
    <AppFlex
      gap="2"
      :justify="centered ? 'center' : 'start'"
      :direction="reverse ? 'row-rev' : 'row'"
      align="center"
    >
      <!-- Spinner -->
      <AppSpinner
        v-if="loading"
        class="me-1"
      />

      <!-- Icon -->
      <AppIcon
        v-if="icon"
        :name="icon"
        :variant="iconVariant"
        :color="iconColor"
        :size="iconSizeValue"
      />

      <!-- Content -->
      <slot>
        <AppText
          v-if="text"
          :type="textColorType"
          :size="textSize"
          :class="textClass"
        >
          {{ text }}
        </AppText>
      </slot>
    </AppFlex>
  </button>
</template>
