<script>
export default {
  name: "AppImage"
}
</script>

<script setup>
defineProps({
  src: String,
  alt: String,
  width: [String, Number],
  height: [String, Number]
})
</script>

<template>
  <img
    :src="src"
    :alt="alt"
    :width="width"
    :height="height"
  >
</template>
