import { createApp } from "vue"

import { AppToast } from "@/components/ui"

// Function to create toast functions and properties
export const createToastFunctions = () => {
  let container
  let toastApp

  // Base properties for toast
  const baseProps = {
    close: () => {
      // If toastApp is mounted, unmount it
      if (toastApp)
        toastApp.unmount(container)

      // Find the container element and remove it from the body
      container = document.querySelector("#ToastPlug")
      if (container)
        document.body.removeChild(container)
    }
  }

  // Toast function to display a new toast
  const toast = params => {
    // Merge base properties with provided parameters
    const props = { ...baseProps, ...params }

    // Close any existing toast
    props.close()

    // Create a new container for the toast
    container = document.createElement("div")
    container.setAttribute("id", "ToastPlug")
    document.body.appendChild(container)

    // Create and mount a new toast app instance
    toastApp = createApp(AppToast, props)
    toastApp.mount(container)
  }

  // Return the toast function and base properties
  return { toast, baseProps }
}

// Function to install toast methods globally in Vue app
const install = app => {
  // Create toast functions and properties
  const { toast, baseProps } = createToastFunctions()

  // Add toast and closeToast methods to global properties of Vue app
  app.config.globalProperties.$toast = toast
  app.config.globalProperties.$closeToast = baseProps.close
}

export default install
