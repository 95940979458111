<script>
export default {
  name: "AppProgress"
}
</script>

<script setup>
import { AppText } from ".."

import { useAppProgress } from "./composables/useAppProgress"

import { AppFlex } from "@/components/layouts"

const props = defineProps({
  value: {
    type: [Number, String],
    default: 50,
    validator: value => Number(value) >= 1 && Number(value) <= 100
  },
  unit: {
    type: String,
    default: "%"
  },
  text: {
    type: String,
    default: null
  }
})

const {
  circleRadiusBg,
  circleRadiusFg,
  circumference,
  STYLES,
  offset,
  svgStyle,
  viewBox,
  wrapStyle
} = useAppProgress(Number(props.value))
</script>

<template>
  <div>
    <AppFlex direction="col" gap="2" align="center">
      <!-- Text -->
      <AppText v-if="text">
        {{ text }}
      </AppText>

      <!-- Circle Content -->
      <div :style="wrapStyle" class="app-circular-progressbar">
        <svg :style="svgStyle" :viewBox="viewBox">

          <!-- Empty Circle -->
          <circle
            class="empty-bar"
            :cx="STYLES.SIZE"
            :cy="STYLES.SIZE"
            :r="circleRadiusBg"
            :stroke-width="STYLES.BORDER_WIDTH"
            fill="none"
          />

          <!-- Progress Bar -->
          <circle
            class="progress-bar"
            :cx="STYLES.SIZE"
            :cy="STYLES.SIZE"
            :r="circleRadiusFg"
            :stroke-dasharray="circumference"
            :stroke-dashoffset="offset"
            :stroke-width="STYLES.BORDER_WIDTH"
            fill="none"
            stroke-linecap="round"
          />
        </svg>

        <!-- Current Value -->
        <span class="current-counter">
          {{ value }} <small class="unit">{{ unit }}</small>
        </span>
      </div>
    </AppFlex>
  </div>
</template>

<style
  src="./styles/app-progress.styles.scss"
  lang="scss"
  scoped
/>
