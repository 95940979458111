<script>
export default {
  name: "AppWizardToolBar"
}
</script>

<script setup>
import { AppDivider, AppIcon, AppText } from ".."

import { useWindowSize } from "@/composables"
import { ICON } from "@/lib/constants"

defineProps({
  currentStep: Number,
  steps: Array
})

const { isMobile } = useWindowSize()
</script>

<template>
  <div class="app-wizard-toolbar">
    <!-- Stepper -->
    <div
      v-for="(step, index) in steps"
      :key="index"
      class="step-content"
    >
      <!-- Badge -->
      <span
        class="fs-5 badge rounded-pill text-white"
        :class="{
          'bg-primary': step?.value === currentStep,
          'completed': currentStep > step?.value,
          'no-completed': currentStep < step?.value
        }"
      >
        <!-- Step Value -->
        {{ currentStep > step.value ? "" : step.value }}

        <!-- Icon -->
        <AppIcon
          v-show="currentStep > step.value"
          size="24"
          variant="solid"
          class="step-icon"
          :name="ICON.CHECK"
        />
      </span>

      <!-- Step text -->
      <AppText
        v-if="!isMobile"
        :type="currentStep < step.value ? 'muted' : 'black'"
        class="step-text"
      >
        {{ step?.text }}
      </AppText>

      <!-- Separator -->
      <AppDivider
        v-if="index < steps.length - 1 && !isMobile"
        class="step-divider"
      />
    </div>
  </div>
</template>

<style
    src="./styles/app-wizard-toolbar.styles.scss"
    lang="scss"
    scoped
/>