<script>
export default {
  name: "AppHeaderSidebar"
}
</script>

<script setup>
import { AppHeaderLinkItem, AppHeaderLogoutButton } from "../.."
import { useSidebarLinkList } from "../../../composables/useSidebarLinkList"

import { AppFlex } from "@/components/layouts"
import { AppDivider, AppCheckboxMenu } from "@/components/ui"
import { useUiStore } from "@/stores"

const ui = useUiStore()
const { linkItems } = useSidebarLinkList()
</script>

<template>
  <div
    :class="[
      'position-fixed sm-transition',
      'top-0 start-0 h-100 z-1',
    ]"
    :style="`width: ${ui.sidebarWith}px`"
  >
    <AppFlex direction="col">
      <AppFlex
        gap="3"
        justify="between"
        align="center"
        class="bg-primary sidebar-header"
      >
        <!-- Menu icon -->
        <div class="ml-2">
          <AppCheckboxMenu
            :checked="ui.sidebarIsOpen"
            @on-change="ui.toogleSidebar"
          />
        </div>

        <AppDivider type="vertical" />
      </AppFlex>

      <!-- Link Items -->
      <AppFlex
        gap="4"
        direction="col"
        class="mt-5 ml-2"
      >
        <AppHeaderLinkItem
          v-for="(link, index) in linkItems"
          :key="index"
          :link="link"
        />
      </AppFlex>

      <!-- Logout Button -->
      <div class="logout-button-container">
        <AppHeaderLogoutButton />
      </div>
    </AppFlex>
  </div>
</template>

<style lang="scss" scoped>
.sidebar-header {
  height: $header-heigth;
  padding: 0 10px 0 10px;
}

.logout-button-container {
  position: fixed;
  top: 90%;
  left: 30px;
}
</style>