import { defineStore } from "pinia"

import { ENDPOINTS, METHODS } from "@/lib/constants"

export default defineStore({
  id: "header-alerts",
  state: () => ({
    alertsCount: 0,
    alerts: []
  }),

  // Actions
  actions: {
    async fetchAlertsCount(request) {
      try {
        const { data } = await request({
          method: METHODS.GET,
          url: ENDPOINTS.ADMIN.UNRESOLVED_ALERTS_COUNT
        })
        this.alertsCount = data?.response?.UnsolvedCount
      } catch (error) {
        console.warn(error)
      }
    },

    async fetchUnsolvedAlerts(request) {
      try {
        const filters = "?Limit=5&Offset=0"
        const { data } = await request({
          method: METHODS.GET,
          url: `${ENDPOINTS.ADMIN.BASE.UNSOLVED_ALERTS}${filters}`
        })
        this.alerts = data?.response?.Alerts
      } catch (error) {
        console.warn(error)
      }
    },

    async readAlert(alertId, request) {
      try {
        await request({ method: METHODS.PUT, url: `${ENDPOINTS.ADMIN.SOLVE_ALERT}/${alertId}` })
        this.alerts = this.alerts?.map(alert => {
          if (+alert?.AlertID !== +alertId) return alert
          return { ...alert, Unsolved: false }
        })
      } catch (error) {
        console.warn(error)
      }
    }
  }
})
