<script>
export default {
  name: "AppLink"
}
</script>

<script setup>
import { AppText, AppIcon } from ".."

defineProps({
  to: {
    type: [String, Object],
    required: true
  },
  target: {
    type: String,
    default: "_self"
  },
  rel: {
    type: String,
    default: "noopener noreferrer"
  },
  text: {
    type: String,
    default: null
  },
  linkClasses: {
    type: [String, Array],
    default: null
  },
  icon: {
    type: String,
    default: null
  },
  variant: {
    type: String,
    default: "router",
    validator: value => ["router", "link"].includes(value)
  }
})
</script>

<template>
  <div :class="linkClasses">
    <!-- Vue default link -->
    <router-link
      v-if="variant === 'router'"
      :to="to"
      :target="target"
      :rel="rel"
      :class="linkClasses"
    >
      <slot>
        <!-- Text -->
        <AppText
          v-if="text"
          type="primary"
          class="round-hover"
        >
          {{ text }}
        </AppText>

        <!-- Icon -->
        <AppIcon
          v-if="icon"
          :name="icon"
          color="primary"
          size="18"
          class="app-link-icon"
        />
      </slot>
    </router-link>

    <!-- HTML default link -->
    <a
      v-else
      :href="to"
      :target="target"
      :rel="rel"
    >
      <slot />
    </a>
  </div>
</template>

<style scoped lang="scss">
a {
  display: inline-block;
  transition: background-color 0.3s ease-in-out;
  text-decoration: none;
}

.app-link-icon {
  position: relative;
  bottom: 3px;
  margin-left: 5px;
}
</style>
