<script>
export default {
  name: "AppCard"
}
</script>

<script setup>
import { computed } from "vue"

import { AppDivider, AppSkeleton } from ".."

import { AppPageTitle } from "@/components/common"

const props = defineProps({
  title: {
    type: String,
    default: null
  },
  backRoute: {
    type: String,
    default: null
  },
  loading: {
    type: Boolean,
    default: false
  },
  showDivider: {
    type: Boolean,
    default: true
  },
  align: {
    type: String,
    default: "start",
    validator: value => ["start", "center"].includes(value)
  }
})

const cardClasses = computed(() => {
  const classes = ["card"]

  const alignClass = {
    start: "text-start",
    center: "text-center"
  }[props.align]

  classes.push(
    // Align content
    ...([alignClass]),
  )

  return classes
})
</script>

<template>
  <div :class="cardClasses">
    <!-- All Content -->
    <div :class="{'px-3' : $slots.content}">
      <!-- Loader -->
      <AppSkeleton v-if="loading" />

      <!-- Slot content if is not loading -->
      <slot v-else name="content">
        <!-- Body -->
        <div class="card-body">
          <!-- Card Header -->
          <slot name="header">
            <!-- Title -->
            <template v-if="title">
              <AppPageTitle
                :text="title"
                :route="backRoute"
              />

              <AppDivider
                v-if="showDivider"
                class="mt-2 mb-4"
              />
            </template>
          </slot>

          <!-- Default  -->
          <slot />
        </div>
      </slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card {
  border-radius: 16px;
  padding: 15px;
  box-shadow: 4px 5px 18px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 5px 5px 18px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 5px 5px 18px rgba(0, 0, 0, 0.08);
}
</style>