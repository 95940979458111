<script setup>
import { watch, onMounted, onUnmounted } from "vue"

import { AppFlex } from "../.."

import {
  AppHeaderMobileSidebar,
  AppHeaderNotificationsIcon,
  AppHeaderSidebar,
  UserProfileHeader
} from "./components"

import { AppDevelopmentBanner, AppLogo } from "@/components/common"
import { AppCheckboxMenu } from "@/components/ui"
import { useAxios, useHtmlDocument, useWindowSize } from "@/composables"
import { IS_ADMIN_ENTITY } from "@/lib/constants"
import { scroll } from "@/lib/services"
import { useHeaderAlertStore, useUiStore } from "@/stores"

const ui = useUiStore()
const { request } = useAxios()
const { setOverFlow } = useHtmlDocument()
const { isMobile } = useWindowSize()
const headerAlerts = useHeaderAlertStore()

watch(() => ui.sidebarIsOpen, isVisible => {
  if (isMobile.value) setOverFlow(isVisible ? "hidden" : "auto")
})

const handleMobileSidebarClick = () => {
  scroll.fastTop()
  ui.toogleSidebar()
}

let intervalId

onMounted(async () => {
  // Fetch Admin Alerts
  if (IS_ADMIN_ENTITY) {
    await headerAlerts.fetchUnsolvedAlerts(request)
    await headerAlerts.fetchAlertsCount(request)

    // Set an interval to call fetchUnsolvedAlerts every 30 seconds
    intervalId = setInterval(async () => {
      await headerAlerts.fetchUnsolvedAlerts(request)
      await headerAlerts.fetchAlertsCount(request)
    }, 30000)
  }
})

onUnmounted(() => {
  if (intervalId) {
    clearInterval(intervalId)
  }
})
</script>

<template>
  <section
    class="app-header position-relative text-white sm-transition"
    :style="!isMobile ? ui.headerStyles : 'width: 100%;'"
  >
    <header class="navbar px-4 sticky-top">
      <!-- Mobile Sidebar open -->
      <div v-if="isMobile">
        <AppCheckboxMenu
          :checked="ui.sidebarIsOpen"
          @on-change="handleMobileSidebarClick"
        />
      </div>

      <!-- Logo -->
      <AppLogo class="ms-4" />

      <!-- End Header -->
      <AppFlex justify="end" gap="4" align="center">
        <!-- Notifications Icon for admin entity-->
        <AppHeaderNotificationsIcon v-if="IS_ADMIN_ENTITY" />

        <!-- User Profile Link -->
        <UserProfileHeader
          v-if="!isMobile"
          class="me-4"
        />
      </AppFlex>
    </header>

    <!-- Main Router View -->
    <div class="full-content-heigth">
      <!-- Dev Banner -->
      <AppDevelopmentBanner />

      <div class="py-4 px-3">
        <slot />
      </div>
    </div>
  </section>

  <!-- Desktop Sidebar -->
  <AppHeaderSidebar v-if="!isMobile" />

  <!-- Sidebar Mobile content -->
  <AppHeaderMobileSidebar
    v-if="isMobile && ui.sidebarIsOpen"
  />
</template>
