<script>
export default {
  name: "AppDropdown"
}
</script>

<script setup>
import { ref, watch } from "vue"

import { AppIcon, AppText } from ".."

import { useAppDropdown } from "./composables/useAppDropdown"

import { AppFlex } from "@/components/layouts"
import { ICON } from "@/lib/constants"

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: null
  },
  displayProperty: {
    type: String,
    default: "text"
  },
  valueProperty: {
    type: String,
    default: "key"
  },
  options: {
    type: Array,
    default: []
  }
})
const emit = defineEmits(["updateModelValue"])

const selectedItem = ref(props?.modelValue)

const {
  // States
  currentDropDownText,
  dropDownIsVisible,

  // Functions
  onItemClicked,
  toogleDropdown
} = useAppDropdown(props, emit, selectedItem)

watch(() => props?.modelValue, newValue => {
  selectedItem.value = newValue
})

</script>

<template>
  <div
    v-click-outside="() => dropDownIsVisible = false"
    class="app-dropdown"
    @click="toogleDropdown"
  >
    <!-- Dropdown Button -->
    <AppFlex gap="2" align="center" class="pointer px-2 round-hover">
      <!-- Sort icon -->
      <AppIcon
        :name="ICON.ADJUSTMENTS_HORIZONTAL"
        color="primary"
        size="18"
      />

      <!-- Current Text -->
      <AppText type="primary" class="mt-1">
        {{ currentDropDownText }}
      </AppText>

      <!-- Arrow icon -->
      <AppIcon
        :name="ICON.CHEVRON_DOWN"
        color="primary"
        size="18"
        :class="{ 'down-icon-opened' : dropDownIsVisible}"
      />
    </AppFlex>

    <!-- Items -->
    <div
      v-if="dropDownIsVisible"
      class="fade-in app-dropdown-items"
    >
      <!-- Empty Option -->
      <span
        v-if="options?.length === 0"
        class="dropdown-item"
        :disabled="true"
      >
        {{ $t("General.NoRecords") }}
      </span>

      <!-- All Options -->
      <template v-else>
        <span
          v-for="(option, index) in options"
          :key="index"
          :class="['dropdown-item', { 'active-item': option?.[valueProperty] === selectedItem }]"
          @click="onItemClicked(option?.[valueProperty])"
        >
          {{ option?.[displayProperty] }}
        </span>
      </template>
    </div>
  </div>
</template>

<style
  src="./styles/app-dropdown.styles.scss"
  lang="scss"
  scoped
/>