<script>
export default {
  name: "NotificationItem"
}
</script>

<script setup>
import { ref } from "vue"

import { AppLabels } from "@/components/common"
import { AppFlex } from "@/components/layouts"
import { AppButton, AppButtonCollapse, AppDivider, AppLink, AppIcon, AppImage, AppText } from "@/components/ui"
import { useWindowSize } from "@/composables"
import { ICON, ROUTE_NAMES, UI } from "@/lib/constants"

const { notification, showRedirect, showDivider } = defineProps({
  notification: Object,
  showRedirect: {
    type: Boolean,
    default: true
  },
  showDivider: {
    type: Boolean,
    default: true
  },
  showCta: {
    type: Boolean,
    default: true
  }
})
defineEmits(["handleReadAlert"])

const userDataIsVisible = ref(false)
const driverIsVisible = ref(notification?.Driver?.Name && notification?.Driver?.Phone)
const { isMobile } = useWindowSize()

const routeRedirection = ref({
  name: ROUTE_NAMES.ADMIN.ROUTES.DETAILS,
  params: { id: notification?.Route?.RouteID }
})
</script>

<template>
  <div class="p-3">
    <AppFlex gap="3" class="position-relative">
      <!-- Image -->
      <AppImage
        :src="notification?.AlertType?.Icon"
        :alt="`notification-image-${notification?.AlertID}`"
        width="60"
        height="60"
        class="position-absolute top-0 left-0"
      />

      <!-- Texts -->
      <AppFlex
        gap="2"
        direction="col"
        class="w-100 mt-1 ms-6"
      >
        <AppFlex justify="between" xsdir="col">
          <!-- Name -->
          <AppFlex gap="2" align="center">
            <AppImage
              v-if="notification?.Unsolved"
              :src="UI.IMAGE_PATHS.POINT"
              width="8"
              height="8"
              alt="point"
            />
            <AppFlex direction="col">
              <!-- Name -->
              <AppText :weight="notification?.Unsolved ? 'bold' : 'normal'">
                {{ notification?.AlertType?.Name }}
              </AppText>

              <!-- Content -->
              <AppText v-if="notification?.Content" tag="small">
                {{ notification?.Content }}
              </AppText>
            </AppFlex>
          </AppFlex>

          <!-- Date -->
          <AppText type="muted">
            {{ notification?.CreatedAt }}
          </AppText>
        </AppFlex>

        <AppFlex justify="between" xsdir="col">
          <AppFlex gap="3" xsdir="col">
            <!-- Collapsable -->
            <div v-if="driverIsVisible" class="me-2">
              <AppButtonCollapse
                class="notification-card-button-collapse"
                :visible="userDataIsVisible"
                @updateVisibility="userDataIsVisible = !userDataIsVisible"
              />
            </div>

            <AppFlex direction="col">
              <AppFlex gap="3" class="mb-2" xsdir="col">
                <!-- Route Code -->
                <AppText weight="bold">
                  #{{ notification?.Route?.Code }}
                </AppText>

                <!-- Route redirection -->
                <AppLink
                  v-if="showRedirect"
                  :to="routeRedirection"
                  :text="$t('Admin.Alerts.Show')"
                  :icon="ICON.CHEVRON_RIGHT"
                />
              </AppFlex>

              <!-- Origin -->
              <AppText>
                {{ notification?.Route?.Origin }}
              </AppText>

              <!-- Destination -->
              <AppText>
                {{ notification?.Route?.Destination }}
              </AppText>
            </AppFlex>
          </AppFlex>

          <!-- Managed icon -->
          <AppText v-if="!notification?.Unsolved && showCta" :icon="ICON.CHECK">
            {{ $t('General.Managed') }}
          </AppText>

          <!-- Manage Button -->
          <div v-else>
            <AppButton
              v-if="showCta"
              size="md"
              :outline="true"
              variant="primary"
              type="button"
              :text="$t('Admin.Alerts.Mark')"
              @on-clicked="() => $emit('handleReadAlert', notification?.AlertID)"
            />
          </div>
        </AppFlex>

        <!-- User Content -->
        <AppFlex
          v-if="userDataIsVisible"
          gap="3"
          :class="['mt-3 fade-in', {'ms-5': !isMobile}]"
        >
          <!-- Icon user -->
          <AppIcon
            :name="ICON.USER"
            color="primary"
            variant="solid"
          />

          <!-- User Data -->
          <AppLabels
            gap="1"
            title-weight="normal"
            :title="notification?.Driver?.Name"
            :subtitle="$t('General.Phone', { value: notification?.Driver?.Phone })"
          />
        </AppFlex>
      </AppFlex>
    </AppFlex>

    <AppDivider v-if="showDivider" class="mt-4" />
  </div>
</template>
