import { BOOTSTRAP, MAP } from "@/lib/constants"

class AdminRouteService {
  constructor() {}

  getRouteTypeAlertVariant(id) {
    return {
      1: BOOTSTRAP.TYPES.SILVER,
      2: BOOTSTRAP.TYPES.BLUSH,
      3: BOOTSTRAP.TYPES.BLUSH,
      4: BOOTSTRAP.TYPES.BLUSH,
      5: BOOTSTRAP.TYPES.BLUSH,
      6: BOOTSTRAP.TYPES.BLUSH,
      7: BOOTSTRAP.TYPES.BLUSH,
      8: BOOTSTRAP.TYPES.BLUSH,
      9: BOOTSTRAP.TYPES.SECONDARY,
      10: BOOTSTRAP.TYPES.SUNSHINE,
      11: BOOTSTRAP.TYPES.SUCCESS
    }[+id] || BOOTSTRAP.TYPES.SECONDARY
  }

  getIconMapByRouteStatus(id) {
    return {
      1: MAP.ICON_VARIANTS.UNSTARTED_ROUTE,
      2: MAP.ICON_VARIANTS.ROUTE_IN_SQUARE,
      3: MAP.ICON_VARIANTS.ROUTE_IN_SQUARE,
      4: MAP.ICON_VARIANTS.ROUTE_IN_SQUARE,
      5: MAP.ICON_VARIANTS.ROUTE_IN_SQUARE,
      6: MAP.ICON_VARIANTS.ROUTE_IN_SQUARE,
      7: MAP.ICON_VARIANTS.ROUTE_IN_SQUARE,
      8: MAP.ICON_VARIANTS.ROUTE_IN_SQUARE,
      9: MAP.ICON_VARIANTS.IN_PROGRESS_ROUTE,
      10: MAP.ICON_VARIANTS.STOPPED_ROUTE,
      11: MAP.ICON_VARIANTS.FINISHED_ROUTE
    }[+id] || MAP.ICON_VARIANTS.IN_PROGRESS_ROUTE
  }

  buildRoutePanelMap(routes, pois) {
    // Build Markers
    const routesMapItems = routes?.map(route => ({
      lat: route?.LatestPositionLatitude,
      lon: route?.LatestPositionLongitude,
      variant: this.getIconMapByRouteStatus(route?.RouteStatusID),
      hover: true,
      redirectUrl: `/admin/routes-panel/${route?.RouteID}/details`
    }))

    // Build Pois
    const poisMapItems = pois?.map(poi => ({
      lat: poi?.Latitude,
      lon: poi?.Longitude,
      variant: MAP.ICON_VARIANTS.PDI_ROUTE,
      popup: {
        type: MAP.POPUP_VARIANT.PDI,
        code: poi?.Code,
        name: poi?.Name,
        squareType: poi?.Platform,
        operationalType: poi?.OperativeType,
        zone: poi?.Nemonic,
        address: poi?.Division
      }
    }))

    return [...routesMapItems, ...poisMapItems?.slice(0, 300)]
  }
}

const adminRouteService = new AdminRouteService()
export default adminRouteService