<script>
export default {
  name: "AppTableProvider"
}
</script>

<script setup>
import { watch, onMounted, ref } from "vue"

import { useAppTableProvider } from "./composables/useAppTableProvider"

import { AppAlert } from "@/components/ui"

const props = defineProps({
  providerkey: {
    type: String,
    required: true
  },
  url: {
    type: String,
    required: true
  },
  tablekey: {
    type: String,
    default: "data"
  },
  usePagination: {
    type: Boolean,
    default: true
  },
  useCache: {
    type: Boolean,
    default: true
  },
  filters: {
    type: Object,
    default: {}
  }
})

const urlRef = ref(props.url)

const {
  // States
  data,
  error,

  // Functions
  getFiltersUrl,
  fetchData
} = useAppTableProvider(props, urlRef)

watch(() => props.providerkey, () => fetchData(false))
watch(() => props.url, newUrl => urlRef.value = newUrl)

onMounted(() => fetchData(props.useCache))
</script>

<template>
  <div>
    <!-- Error -->
    <AppAlert
      v-if="error"
      class="m-2"
      variant="danger"
      :text="`Error: ${error}`"
    />

    <!-- Main data -->
    <template v-if="!error && data">
      <slot
        :data="data"
        :get-filters-url="getFiltersUrl"
      />
    </template>
  </div>
</template>
