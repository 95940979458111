export const BOOTSTRAP = {
  TYPES: {
    PRIMARY: "primary",
    SECONDARY: "secondary",
    INFO: "info",
    SUCCESS: "success",
    WARNING: "warning",
    DANGER: "danger",
    DARK: "dark",
    SILVER: "silver",
    SUNSHINE: "sunshine",
    BLUSH: "blush"
  }
}

Object.freeze(BOOTSTRAP)