import { ROUTE_NAMES } from "@/lib/constants"
import { useAuthStore } from "@/stores"

export const loginGuard = next => {
  const auth = useAuthStore()

  // If user is Authenticated, redirect to home
  if (auth.isAuthenticated) next({ name: ROUTE_NAMES.HOME })

  // Otherwise is enable for Auth Pages
  else next()
}