import { computed } from "vue"

import { useRouter } from "vue-router"

import { mapFiguresManager, mapManager, markerManager } from "../managers"

import { useWindowSize } from "@/composables"

export const useAppMap = params => {
  // Destructure all params
  const { props, emit, mapRef, markers, circleRadius, polygon, existingPolygons } = params

  // Destructure props
  const { location, zoom, height, width, drawPolygon, allowClick } = props

  const { isMobile } = useWindowSize()
  const router = useRouter()

  let leafletMap = null

  const initMap = () => {
    // Init Map
    leafletMap = mapManager.initMap({ mapRef, location, zoom })

    // Markers
    loadMarkers()
  }

  const loadMarkers = () => {
    const markerGroup = markerManager.getMarkerGroup()

    // Add markers to the layer
    for (const marker of markers.value) {
      const currentMarker = markerManager.displayMarker(leafletMap, marker)

      // Set Hover Animation Functions
      if (marker?.hover) {
        currentMarker.on("mouseover", e => {
          if (marker?.hoverIcon) markerManager.changeMarkerIcon(currentMarker, marker?.hoverIcon)
          markerManager.onMouseHover(e)
        })
        currentMarker.on("mouseout", e => {
          if (marker?.hoverIcon) markerManager.changeMarkerIcon(currentMarker, marker?.variant)
          markerManager.onMouseOut(e)
        })
      }

      // Check for redirectUrl and set click event
      if (marker?.redirectUrl) {
        currentMarker.on("click", () => {
          router.push(marker.redirectUrl)
        })
      }
      // Set current marker
      markerGroup.addLayer(currentMarker)
    }

    // Set Zoom
    markerManager.setZoomBasedOnmarkers(leafletMap)

    // Save all mak
    markerManager.setMapMarkers(markers.value)

    // Display marker group
    leafletMap.addLayer(markerGroup)

    // Show Circle if exists
    if (circleRadius.value) showMapCircle()

    // Listen for click on map
    if (allowClick) mapManager.listenClickEvents(leafletMap, emit)

    // Add polygon functions based on props
    if (polygon.value?.coordinates?.length > 0 || drawPolygon) {
      if (drawPolygon) mapFiguresManager.addDrawPolygonControl(leafletMap)
      mapFiguresManager.showPolygon(leafletMap, polygon.value)
      mapFiguresManager.listenToDrawnPolygon(leafletMap, emit, existingPolygons.value)
      mapFiguresManager.showExistinPolygons(leafletMap, polygon.value, existingPolygons.value)
    }
  }

  // Function to reload map
  const reloadMap = () => {
    // Delete all markers
    markerManager.clearLayers()
    mapManager.deleteAllMarkers(leafletMap)

    // Load markers again
    loadMarkers()

    // Set new Location for Location Variants
    mapManager.setDefaultLocation({ map: leafletMap, zoom })
  }

  // Function to show circle
  const showMapCircle = () => {
    mapFiguresManager.removeCurrentCircle()
    mapFiguresManager.showCircle({
      map: leafletMap,
      location: markerManager.getCurrentMarkerLocation(),
      radius: circleRadius.value
    })
  }

  // Function to draw Polygons
  const showPolygon = () => {
    mapFiguresManager.removeCurrentPolygon(leafletMap)
    mapFiguresManager.showPolygon(leafletMap, polygon.value)
    mapFiguresManager.showExistinPolygons(leafletMap, polygon.value, existingPolygons.value)
  }

  const showPolygons = () => {
    mapFiguresManager.showExistinPolygons(leafletMap, polygon.value, existingPolygons.value)
  }

  const mapWrapperStyles = computed(() => {
    const defaultHeigth = isMobile.value ? "400px" : height
    return { height: defaultHeigth, width: width }
  })

  return {
    // States
    leafletMap,
    mapWrapperStyles,

    // Functions
    showPolygons,
    initMap,
    reloadMap,
    showPolygon,
    showMapCircle
  }
}
