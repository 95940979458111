<script>
export default {
  name: "AppTableHeader"
}
</script>

<script setup>
import { AppFlex } from "@/components/layouts"
import { AppButton } from "@/components/ui"
import { AppSearchFilter } from "@/components/ui/_fields"
import { ICON } from "@/lib/constants"

defineProps({
  filter: String,
  text: String,
  showCreate: {
    type: Boolean,
    default: true
  }
})
defineEmits(["updateFilter", "onClicked"])
</script>

<template>
  <div>
    <AppFlex justify="between" gap="3" xsdir="col">
      <!-- Search -->
      <AppSearchFilter
        class="w-30 w-sm-100"
        :model-value="filter"
        :display-label="false"
        @update:modelValue="$emit('updateFilter', $event)"
      />

      <!-- Button -->
      <div v-if="showCreate">
        <AppButton
          :icon="ICON.PLUS"
          :text="text"
          class="w-sm-100"
          @on-clicked="$emit('onClicked')"
        />
      </div>
    </AppFlex>
  </div>
</template>
