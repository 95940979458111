import { ref, computed } from "vue"

import { ICON } from "@/lib/constants"

export const useAppAlert = props => {
  const closed = ref(false)
  const { variant, shape, size, showIcon } = props

  const alertClasses = computed(() => {
    const classes = ["alert"]

    classes.push(
      // Variant
      ...(variant ? [`alert-${variant}`] : []),

      // Border Radius class
      ...([shape]),

      // Size
      ...(size === "sm" ? ["app-alert-small"]: [])
    )
    return classes
  })

  const icon = computed(() => {
    if (!showIcon) return null

    return {
      success: ICON.CHECK,
      info: ICON.INFORMATION_CIRCLE,
      primary: ICON.INFORMATION_CIRCLE,
      secondary: ICON.INFORMATION_CIRCLE,
      warning: ICON.EXCLAMATION_TRIANGLE,
      danger: ICON.EXCLAMATION_TRIANGLE,
      dark: ICON.INFORMATION_CIRCLE
    }[variant]
  })

  const handleClose = () => {
    closed.value = true
  }

  return {
    // States
    alertClasses,
    icon,
    closed,

    // Function
    handleClose
  }
}