<script>
export default {
  name: "SmallNotificationItem"
}
</script>

<script setup>
import { AppFlex } from "@/components/layouts"
import { AppButton, AppDivider, AppImage, AppText } from "@/components/ui"
import { UI } from "@/lib/constants"

defineProps({ notification: Object })
defineEmits(["handleReadAlert"])
</script>

<template>
  <div class="p-3">
    <AppFlex justify="between" xsdir="col" align="center" class="align-sm-unset">
      <AppFlex gap="3">
        <!-- Image -->
        <AppImage
          :src="notification?.AlertTypeIcon"
          :alt="`notification-image-${notification?.AlertID}`"
          width="60"
          height="60"
        />

        <AppFlex direction="col" gap="1">
          <!-- Date -->
          <AppText type="muted" class="mt-2">
            {{ notification?.CreatedAt }}
          </AppText>

          <!-- Name -->
          <AppFlex gap="2" align="center">
            <!-- Point -->
            <AppImage
              v-if="notification?.Unsolved"
              :src="UI.IMAGE_PATHS.POINT"
              width="8"
              height="8"
              alt="point"
            />
            <!-- Name -->
            <AppText :weight="notification?.Unsolved ? 'bold' : 'normal'">
              {{ notification?.AlertTypeName }}
            </AppText>
          </AppFlex>

          <!-- Content -->
          <AppText v-if="notification?.Content" tag="small">
            {{ notification?.Content }}
          </AppText>
        </AppFlex>
      </AppFlex>

      <!-- Manage Button -->
      <div>
        <AppButton
          v-if="notification?.Unsolved"
          :outline="true"
          variant="primary"
          :text="$t('Admin.Alerts.Mark')"
          class="mt-sm-1 w-sm-100"
          @on-clicked="$emit('handleReadAlert', notification)"
        />
      </div>
    </AppFlex>

    <AppDivider class="mt-4" />
  </div>
</template>
