import { defineStore } from "pinia"

import { ENV } from "@/lib/constants"

// Example response
// {
//    url: "",
//    data: "",
//    expiresAt: ""
// }[]

export default defineStore({
  id: "cache",
  state: () => ({
    responses: []
  }),

  // Getters
  getters: {
    // Get Response by URL
    getResponseByUrl: state => url => {
      return state.responses.find(response => response.url === url)?.data
    }
  },

  // Actions (mutations)
  actions: {

    // Add or Update Response on Cache
    setResponse(url, data) {
      const index = this.responses.findIndex(response => response.url === url)

      // Set expiration time
      const value = parseInt(ENV.CACHE_EXPIRED_VALUE)
      const expiresAt = new Date(new Date().getTime() + value).toISOString()

      if (index !== -1) {
        this.responses[index] = { url, data, expiresAt }
      } else {
        this.responses.push({ url, data, expiresAt })
      }
    },

    // Remove Response by Url
    removeResponse(url) {
      this.responses = this.responses.filter(response => response.url !== url)
    },

    // Clear expired responses
    cleanExpiredResponses() {
      const now = new Date()
      this.responses = this.responses.filter(response => new Date(response.expiresAt) > now)
    }
  }
})
