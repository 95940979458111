import * as yup from "yup"

import { tc } from "@/lib/services"

export default yup.object().shape({
  /* Name */
  Name: yup.string().required(tc("Validations.Required")),

  /* Code */
  Code: yup.number().required(tc("Validations.Required")),

  /* Neumonic */
  Nemonic: yup.string().required(tc("Validations.Required")),

  /* Type of operation */
  OperativeTypeID:  yup.string().required(tc("Validations.Required")),

  /* Division */
  DivisionID:  yup.string().required(tc("Validations.Required")),

  /* Platform */
  PlatformID: yup.string().required(tc("Validations.Required")),

  /* Province */
  ProvinceID: yup.string().required(tc("Validations.Required")),

  /* Street */
  Street: yup.string().required(tc("Validations.Required")),

  /* City */
  City: yup.string().required(tc("Validations.Required")),

  /* Postal Code */
  PostalCode: yup.string().required(tc("Validations.Required")),

  /* Self Manageable */
  IsSelfManageable: yup.boolean(),

  /* Fill Out Loading Sheet */
  FillOutLoadSheet: yup.boolean()
})
