import L from "leaflet"

import { MAP } from "@/lib/constants"

class MapIconManager {
  constructor() {
    this.icons = {
      // PDI Route
      [MAP.ICON_VARIANTS.PDI_ROUTE]: L.icon({
        iconUrl: MAP.IMAGES.PDI_ROUTE,
        iconSize: [48, 48]
      }),

      // Unstarted Route
      [MAP.ICON_VARIANTS.UNSTARTED_ROUTE]: L.icon({
        iconUrl: MAP.IMAGES.UNSTARTED_ROUTE,
        iconSize: [56, 56]
      }),

      // Route in Square
      [MAP.ICON_VARIANTS.ROUTE_IN_SQUARE]: L.icon({
        iconUrl: MAP.IMAGES.ROUTE_IN_SQUARE,
        iconSize: [56, 56]
      }),

      // In Progress Route
      [MAP.ICON_VARIANTS.IN_PROGRESS_ROUTE]: L.icon({
        iconUrl: MAP.IMAGES.IN_PROGRESS_ROUTE,
        iconSize: [56, 56]
      }),

      // Finished Route
      [MAP.ICON_VARIANTS.FINISHED_ROUTE]: L.icon({
        iconUrl: MAP.IMAGES.FINISHED_ROUTE,
        iconSize: [56, 56]
      }),

      // Stopped Route
      [MAP.ICON_VARIANTS.STOPPED_ROUTE]: L.icon({
        iconUrl: MAP.IMAGES.STOPPED_ROUTE,
        iconSize: [56, 56]
      }),

      // Origin
      [MAP.ICON_VARIANTS.ORIGIN]: L.icon({
        iconUrl: MAP.IMAGES.ORIGIN,
        iconSize: [56, 81]
      }),

      // Origin
      [MAP.ICON_VARIANTS.DESTINY]: L.icon({
        iconUrl: MAP.IMAGES.DESTINY,
        iconSize: [56, 81]
      }),

      // Loading
      [MAP.ICON_VARIANTS.LOADING]: L.icon({
        iconUrl: MAP.IMAGES.LOADING,
        iconSize: [56, 56]
      }),

      // Worker
      [MAP.ICON_VARIANTS.WORKER]: L.icon({
        iconUrl: MAP.IMAGES.WORKER,
        iconSize: [56, 56]
      }),

      // Truck
      [MAP.ICON_VARIANTS.TRUCK]: L.icon({
        iconUrl: MAP.IMAGES.TRUCK,
        iconSize: [56, 56]
      }),

      // Location
      [MAP.ICON_VARIANTS.LOCATION]: L.icon({
        iconUrl: MAP.IMAGES.LOCATION,
        iconSize: [56, 81]
      }),

      // Driver
      [MAP.ICON_VARIANTS.DRIVER]: L.icon({
        iconUrl: MAP.IMAGES.DOT,
        iconSize: [80, 80]
      })
    }
  }

  // Get the icon based on variant
  getIcon(variant) {
    return this.icons[variant]
  }

  // Get the icon based on variant
  buildIcon(iconUrl) {
    return L.icon({
      iconUrl: iconUrl,
      iconSize: [56, 56]
    })
  }
}

export const mapIconManager = new MapIconManager()