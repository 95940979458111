<script>
export default {
  name: "AppMap"
}
</script>

<script setup>
import { ref, onMounted, onUnmounted, watch } from "vue"

import { useAppMap } from "./composables"
import { mapManager } from "./managers"

import { MAP } from "@/lib/constants"

const props = defineProps({
  height: {
    type: String,
    default: "680px"
  },
  width: {
    type: String,
    default: "100%"
  },
  location: {
    type: Array,
    default: () => MAP.LOCATIONS.SPAIN
  },
  markers: {
    type: Array,
    default: []
  },
  zoom: {
    type: Number,
    default: 6
  },
  circleRadius: {
    type: Number,
    default: null
  },
  drawPolygon: {
    type: Boolean,
    default: false
  },
  allowClick: {
    type: Boolean,
    default: false
  },
  existingPolygons: {
    type: Array,
    default: []
  },
  polygon: {
    type: Object,
    default: null
  }
})

const emit = defineEmits([
  "updateLocation",
  "updatePolygon"
])

const map = ref(null)
const markers = ref(props.markers || [])
const circleRadius = ref(props.circleRadius || null)
const polygon = ref(props.polygon || null)
const existingPolygons = ref(props.existingPolygons || null)

const params = {
  props,
  emit,
  mapRef: map,
  markers,
  circleRadius,
  polygon,
  existingPolygons
}

const {
  // State
  leafletMap,
  mapWrapperStyles,

  // Functions
  initMap,
  showPolygons,
  reloadMap,
  showPolygon,
  showMapCircle
} = useAppMap(params)

// Init Map
onMounted(() => initMap())

// Remove map
onUnmounted(() => {
  mapManager.removeMap(leafletMap)
})

// Watch for new Markers
watch(() => props.markers, newMarkers => {
  markers.value = newMarkers
  reloadMap()
})

// Watch for circle
watch(() => props.circleRadius, newCircleRadius => {
  circleRadius.value = newCircleRadius
  showMapCircle()
})

// Watch for Polygon
watch(() => props.polygon, newPolygon => {
  polygon.value = newPolygon
  showPolygon()
})

// Watch for Existings Polygon
watch(() => props.existingPolygons, newValues => {
  existingPolygons.value = newValues
  showPolygons()
})
</script>

<template>
  <div
    ref="map"
    class="app-map"
    :style="mapWrapperStyles"
  />
</template>