import * as yup from "yup"

import { tc } from "@/lib/services"

export default yup.object().shape({
  /* Latitude */
  Latitude: yup.number()
    .transform(value => Number.isNaN(value) ? null : value )
    .required(tc("Validations.Required")),

  /* Longitude */
  Longitude: yup.number()
    .transform(value => Number.isNaN(value) ? null : value )
    .required(tc("Validations.Required")),

  /* Longitude */
  RadiusMeters: yup.number()
    .transform(value => Number.isNaN(value) ? null : value )
    .nullable(true),

  /* Fill Out Loading Sheet */
  DrawArea: yup.boolean(),

  /* Main Area */
  MainArea: yup.object()
})