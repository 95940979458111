export const ICON = {
  ADJUSTMENTS_HORIZONTAL: "AdjustmentsHorizontalIcon",
  ARROW_DOWN_TRAY: "ArrowDownTrayIcon",
  ARROW_DOWN: "ArrowDownIcon",
  ARROW_LEFT: "ArrowLeftIcon",
  ARROW_PATH: "ArrowPathIcon",
  ARROW_RIGTH: "ArrowRightIcon",
  ARROW_UP: "ArrowUpIcon",
  ARROWS_UP_DOWN: "ArrowsUpDownIcon",
  BELL: "BellIcon",
  BUILDING_OFFICE: "BuildingOfficeIcon",
  CALENDAR: "CalendarIcon",
  CALENDAR_DAYS: "CalendarDaysIcon",
  CHART_BAR_SQUARE: "ChartBarSquareIcon",
  CHART_PIE: "ChartPieIcon",
  CHECK: "CheckIcon",
  CHEVRON_DOUBLE_LEFT: "ChevronDoubleLeftIcon",
  CHEVRON_DOUBLE_RIGHT: "ChevronDoubleRightIcon",
  CHEVRON_DOWN: "ChevronDownIcon",
  CHEVRON_LEFT: "ChevronLeftIcon",
  CHEVRON_RIGHT: "ChevronRightIcon",
  COG_6_TOOTH: "Cog6ToothIcon",
  DEVICE_PHONE_MOBILE: "DevicePhoneMobileIcon",
  DOCUMENT_DUPLICATE: "DocumentDuplicateIcon",
  DOCUMENT_TEXT: "DocumentTextIcon",
  EDIT: "PencilSquareIcon",
  EXCLAMATION_CIRCLE: "ExclamationCircleIcon",
  EXCLAMATION_TRIANGLE: "ExclamationTriangleIcon",
  EYE_SLASH: "EyeSlashIcon",
  EYE: "EyeIcon",
  FUNNEL: "FunnelIcon",
  INBOX_ARROW_DOWN: "InboxArrowDownIcon",
  INFORMATION_CIRCLE: "InformationCircleIcon",
  LIST_BULLET: "ListBulletIcon",
  LOGOUT: "ArrowRightStartOnRectangleIcon",
  MAGNIFYING_GLASS: "MagnifyingGlassIcon",
  MAP_PIN: "MapPinIcon",
  MAP: "MapIcon",
  MINUS: "MinusIcon",
  PENCIL: "PencilIcon",
  PLUS: "PlusIcon",
  TRASH: "TrashIcon",
  TRUCK: "TruckIcon",
  USER_CIRCLE: "UserCircleIcon",
  USER: "UserIcon",
  USERS: "UsersIcon",
  WRENCH_SCREW_DRIVER: "WrenchScrewdriverIcon",
  WRENCH: "WrenchIcon",
  X_MARK: "XMarkIcon"
}

Object.freeze(ICON)