import { provide, reactive, toRefs } from "vue"

import { datatableSymbol } from "@/lib/constants"

export const useDataTable = columns => {
  // Reactive model to store state
  const model = reactive({
    dataProviderKey: `data-provider-${Date.now()}`,
    filters: {
      page: 1,
      Offset: 0,
      Limit: 5
    }
  })

  // Function to update the data provider key
  const updateDataProvider = () => {
    model.dataProviderKey = `data-provider-${Date.now()}`
  }

  // Function to update filters and trigger data provider update
  const filterData = filters => {
    // Reset page to 1 when filtering
    model.filters.page = 1
    // Merge new filters with existing ones
    Object.assign(model.filters, filters)
    // Trigger data provider update
    updateDataProvider()
  }

  const updateSorterWithOutValidation = field => {
    model.filters.sorter = {
      column: field.key,
      direction: field.direction
    }
    updateDataProvider()
  }

  // Function to update sorter field and direction
  const updateSorter = field => {
    // Check if field is sortable
    if (!field.sortable) return

    // Update direction for each column
    columns.value.map(item => {
      field.key === item.key ? item.direction = field.direction : item.direction = ""
    })

    // Toggle sort direction
    if (field.direction === "" || !field?.direction) {
      field.direction = "asc"
    } else {
      field.direction = field.direction === "asc" ? "desc" : "asc"
    }

    // Update sorter in filters
    model.filters.sorter = {
      column: field.key,
      direction: field.direction
    }

    // Trigger data provider update
    updateDataProvider()
  }

  const changePerPage = perPage => {
    // Reset page to 1
    model.filters.page = 1

    // Update perPage in filters
    model.filters.Limit = perPage

    // Trigger data provider update
    updateDataProvider()
  }

  // Function to paginate
  const paginate = page => {
    // Update page in filters
    model.filters.Offset = (page - 1) * (model.filters.Limit || 5)
    model.filters.page = page

    // Trigger data provider update
    updateDataProvider()
  }

  // Function to reset filters
  const resetTableFilters = () => {
    model.filters.page = 1
    model.filters.Limit = 5
    model.filters.Offset = 0
  }

  // Provide global functions to use it in AppTable
  provide(datatableSymbol, {
    paginate,
    updateSorter,
    updateSorterWithOutValidation,
    changePerPage,
    updateDataProvider
  })

  // Return reactive references and functions
  return {
    ...toRefs(model),
    resetTableFilters,
    paginate,
    updateSorter,
    updateSorterWithOutValidation,
    updateDataProvider,
    changePerPage,
    filterData
  }
}
