<script>
export default {
  name: "AppDevelopmentBanner"
}
</script>

<script setup>
import { computed } from "vue"

import { AppFlex } from "@/components/layouts"
import { AppText } from "@/components/ui"
import { ENV } from "@/lib/constants"
import { helper } from "@/lib/services"

const props = defineProps({
  applyMargin: {
    type: Boolean,
    default: true
  }
})

const contentStyles = computed(() => {
  if (!props.applyMargin) return {}
  return {
    "margin-top": "7px",
    "border-radius": "8px"
  }
})
</script>

<template>
  <div v-if="!ENV.IS_PRODUCTION" :class="{ 'px-2': applyMargin }">
    <!-- Content -->
    <AppFlex
      class="content"
      justify="center"
      align="center"
      :style="contentStyles"
    >
      <!-- Text -->
      <AppText type="muted">
        {{ $t('General.Enviroment', { value: helper.capitalize(ENV.ENVIROMENT)}) }}
      </AppText>
    </AppFlex>
  </div>
</template>

<style lang="scss" scoped>
.content {
    height: 30px;
    background-color: $primary-soft-blue;
}
</style>