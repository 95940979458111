<script>
export default {
  name: "AppActions"
}
</script>

<script setup>
import { AppFlex } from "@/components/layouts"
import { AppIcon } from "@/components/ui"
import { usePlugins } from "@/composables"
import { ICON } from "@/lib/constants"
import { tc } from "@/lib/services"

const props = defineProps({
  confirm: {
    type: Boolean,
    default: true
  },
  showEdit: {
    type: Boolean,
    default: true
  },
  showDelete: {
    type: Boolean,
    default: true
  }
})

const emit = defineEmits(["edit", "delete"])

const { $alert } = usePlugins()

const handleDelete = () => {
  if (!props.confirm) {
    emit("delete")
    return
  }
  $alert({
    title: tc("General.Attention"),
    message: tc("General.SureDelete"),
    acceptText: tc("General.YesSure"),
    buttonSize: "lg",
    onConfirm: () => emit("delete")
  })
}
</script>

<template>
  <AppFlex gap="4">
    <!-- Edit -->
    <AppIcon
      v-if="showEdit"
      role="button"
      class="round-hover"
      :color="'primary'"
      variant="solid"
      size="20"
      :name="ICON.EDIT"
      @click="$emit('edit')"
    />

    <!-- Delete -->
    <AppIcon
      v-if="showDelete"
      role="button"
      class="round-hover"
      size="20"
      :color="'primary'"
      variant="solid"
      :name="ICON.TRASH"
      @click="handleDelete"
    />
  </AppFlex>
</template>
