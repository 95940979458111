class ScrollService {
  toTop() {
    window.scroll({
      top: 0,
      behavior: "smooth"
    })
  }

  fastTop() {
    window.scrollTo({
      top: 0,
      behavior: "instant"
    })
  }
}

const scroll = new ScrollService()
export default scroll
