import * as yup from "yup"

import { tc } from "@/lib/services"

export default {
  data: {
    Plate: "",
    TrailerDoorTypeID: "",
    Screenprints: "",
    Observations: ""
  },

  schema: yup.object().shape({
    /* Registration */
    Plate: yup.string().required(tc("Validations.Required")),

    /* Gate type */
    TrailerDoorTypeID: yup.string().required(tc("Validations.Required")),

    /* Screen Printing */
    Screenprints: yup.string().required(tc("Validations.Required")),

    /* Observations */
    Observations: yup.string()

  })
}