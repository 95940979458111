<script>
export default {
  name: "AppSpinner"
}
</script>

<template>
  <span
    class="spinner-border spinner-border-sm"
    role="status"
    aria-hidden="true"
  />
</template>
