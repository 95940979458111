import { reactive, computed } from "vue"

export const useAppProgress = value => {
  // Reactive styles configuration
  const STYLES = reactive({
    SIZE: 72,
    BORDER_WIDTH: 5
  })

  // Computed properties to calculate circle radius
  const circleRadiusBg = computed(() => (STYLES.SIZE - STYLES.BORDER_WIDTH) * 0.5)
  const circleRadiusFg = computed(() => (STYLES.SIZE - STYLES.BORDER_WIDTH) * 0.5)

  // Computed property to calculate circumference of the progress circle
  const circumference = computed(() => 2 * Math.PI * circleRadiusFg.value)

  // Computed property to calculate offset based on progress value
  const offset = computed(() => 2 * Math.PI * circleRadiusFg.value * ((100 - value) / 100))

  // Styles for the progress circle container
  const wrapStyle = {
    height: `${STYLES.SIZE}px`,
    width: `${STYLES.SIZE}px`,
    position: "relative"
  }

  // Styles for the SVG element containing the progress circle
  const svgStyle = {
    transform: "rotate(-90deg)",
    overflow: "visible"
  }

  // ViewBox configuration for the SVG element
  const viewBox = `${STYLES.SIZE / 2} ${STYLES.SIZE / 2} ${STYLES.SIZE} ${STYLES.SIZE}`

  return {
    circleRadiusBg,
    circleRadiusFg,
    circumference,
    STYLES,
    offset,
    svgStyle,
    viewBox,
    wrapStyle
  }
}
