import L from "leaflet"

import { markerManager } from "./marker.manager"

import "../scripts/map.full-screen.script"
import { MAP } from "@/lib/constants"
import { tc } from "@/lib/services"

class MapManager {
  constructor() {}

  // Initialize a Leaflet map with provided parameters
  initMap({ mapRef, location, zoom }) {
    // Create a Leaflet map instance and set view to provided location and zoom level
    const currentMap = L.map(mapRef.value, {
      zoomControl: false,
      attributionControl:false
    })

    // Set View
    this.setDefaultLocation({ map: currentMap, location, zoom })

    // Add Custom Zoom
    this.addCustomZoom(currentMap)

    // Add a tile layer to the map using the specified URL template and set zIndex
    L.tileLayer(MAP.URL_TEMPLATE, { zIndex: 0 }).addTo(currentMap)

    // Add Full Screen button
    this.addFullScreen(currentMap)

    // Add Refresh button
    this.addDefaultLocationButton({ currentMap, location, zoom })

    return currentMap
  }

  // Remove map
  removeMap(mapRef) {
    if (mapRef) {
      mapRef.remove()
    }
  }

  // Function to delete all markers
  deleteAllMarkers(mapRef) {
    if (!mapRef) return
    mapRef.eachLayer(layer => {
      if (layer instanceof L.Marker) {
        layer.remove()
      }
    })
  }

  // Function to allow full screen on Map
  addFullScreen(map) {
    L.control
      .fullscreen({
        position: "bottomleft"
      })
      .addTo(map)
  }

  // Function to add custom zoom
  addCustomZoom(map) {
    L.control.zoom({
      position: "topright",
      zoomInTitle: tc("Map.ZoomIn"),
      zoomOutTitle: tc("Map.ZoomOut")
    }).addTo(map)
  }

  // Function to add default location button
  addDefaultLocationButton({ currentMap, location, zoom }) {
    // create custom button
    const customControl = L.Control.extend({
      // button position
      options: { position: "bottomleft" },

      // method
      onAdd: function () {
        // Create custom button
        const btn = L.DomUtil.create("button")

        // Add class to button
        btn.setAttribute("class", "map-reload-btn")
        btn.setAttribute("type", "button")

        // action when clik on button
        btn.onclick = () => {
          const currentLocation = markerManager.getCurrentMarkerLocation()
          currentMap.setView(currentLocation || location, zoom)
        }

        return btn
      }
    })

    // adding new button to map controll
    currentMap.addControl(new customControl())
  }

  // Function to set default location if Exists
  setDefaultLocation({ map, location, zoom }) {
    const currentLocation = markerManager.getCurrentMarkerLocation()
    const finalLocation = currentLocation || location
    if (!finalLocation) return
    map.setView(finalLocation, zoom)
  }

  // Function to get location on map click
  listenClickEvents(map, emit) {
    map.on("click", e => {
      const lat = e.latlng.lat
      const lon = e.latlng.lng
      emit("updateLocation", { lat, lon })
    })
  }
}

export const mapManager = new MapManager()