<script>
export default {
  name: "AppStopsWrapper"
}
</script>

<script setup>
import { ref } from "vue"

import { AppStopItem } from "./components"

import { AppFlex } from "@/components/layouts"
import { AppText } from "@/components/ui"

defineProps({ stops: Array })

const stopsAreVisible = ref(false)
</script>

<template>
  <div>
    <AppFlex gap="2" class="mb-3" xsdir="col">
      <AppText tag="small">
        {{ $t('General.Stops', { value: stops?.length }) }}
      </AppText>

      <AppText
        type="primary"
        class="pointer round-hover text-sm-center"
        tag="small"
        @click="stopsAreVisible = !stopsAreVisible"
      >
        {{ stopsAreVisible ? $t('General.HideItinerary') : $t('General.ShowItinerary') }}
      </AppText>
    </AppFlex>

    <!-- Stops -->
    <AppFlex v-if="stopsAreVisible" direction="col" gap="3">
      <AppStopItem
        v-for="(stop, index) in stops" :key="index"
        :stop="stop"
      />
    </AppFlex>
  </div>
</template>
