import { getActivePinia } from "pinia"

export { default as useUiStore } from "./ui"
export { default as useAuthStore } from "./auth"
export { default as useCacheStore } from "./cache"
export { default as useHeaderAlertStore } from "./header-alerts"

/**
 * Function to reset all active Pinia stores
 */
export const resetAllPiniaStores = () => {
  const pinia = getActivePinia()

  if (!pinia) {
    throw new Error("There is no stores")
  }

  const resetStores = {}

  pinia._s.forEach((store, name) => {
    resetStores[name] = () => store.$reset()
  })

  pinia._s.forEach(store => store.$reset())
}