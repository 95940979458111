import * as yup from "yup"

import { REGULAR_EXPRESIONS } from "@/lib/constants"
import { tc } from "@/lib/services"

const commonValidations = {
  /* Name */
  Name: yup.string().required(tc("Validations.Required")),

  /* Email */
  Email: yup.string()
    .required(tc("Validations.Required"))
    .email(tc("Validations.InvalidEmail")),

  /* Dni */
  Nic: yup.string(),

  /* Phone */
  Phone: yup.string()
    .required(tc("Validations.Required"))
    .transform(value => !value ? "" : value )
    .matches(REGULAR_EXPRESIONS.SPANISH_PHONE_NUMBER, tc("Validations.InvalidPhone")),

  /* Pois */
  Pois: yup.array()
    .required(tc("Validations.Required")),

  /* Main Poi */
  MainPoiID: yup.string()
    .required(tc("Validations.Required"))
}

export default {
  // Form Data Create
  initialValues: {
    Name: "",
    Email: "",
    Nic: "",
    Phone: "",
    Pois: [],
    MainPoiID: null
  },

  // Form Validations
  schemas: {
    create: yup.object().shape({
      ...commonValidations
    }),

    edit: yup.object().shape({
      ...commonValidations,

      /* Password */
      PasswordNew: yup.string()
        .test("password-validation", tc("Validations.InvalidPassword"),
          val => val && val.trim().length > 0 ?
            REGULAR_EXPRESIONS.PASSWORD.test(val) :
            true
        ),

      /* Password Confirm */
      PasswordConfirm: yup.string()
        .oneOf([yup.ref("PasswordNew"), null], tc("Validations.PasswordConfirm"))
    })
  }
}