import { ref } from "vue"

import { useAxios, usePlugins } from "@/composables"
import { METHODS } from "@/lib/constants"
import { useCacheStore } from "@/stores"

// Custom composable for providing data for an application table
export const useAppTableProvider = (props, urlRef) => {
  // Destructuring props to get required values
  const { filters, tablekey, usePagination } = props

  // Using custom composable functions
  const { request } = useAxios()
  const { $showLoader, $closeLoader } = usePlugins()
  const cacheStore = useCacheStore()

  // Reactive references for data and error states
  const data = ref(null)
  const error = ref(null)

  // Function to parse URL filters and update the 'filters' prop
  const parseUrlFilters = () => {
    const urlParts = urlRef.value.split("?")

    if (urlParts.length > 1) {
      // Extracting the query parameters string and splitting it into an array of individual filters
      const arrayOfFilters = urlParts[1].split("&")

      // Iterating over each filter in the array
      arrayOfFilters.forEach(filter => {
        const filterParts = filter.split("=")

        // Decoding and updating the 'filters' object with the parsed key-value pair
        filters[decodeURIComponent(filterParts[0])] = decodeURIComponent(filterParts[1])
      })
    }
  }

  // Function to build API filters based on the 'filters' prop
  const buildApiFilters = () => {
    // Filtering out unwanted keys and building the filter string
    let apiFilters = Object.keys(filters)
      .filter(key => !["sorter", "OrderDesc", "Limit", "Offset", "OrderBy"].includes(key))
      .filter(key => filters[key])
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(filters[key])}`)
      .join("&")

    // Handling sorting parameters
    if (filters.sorter && filters.sorter.column) {
      apiFilters += apiFilters ? "&" : "?"
      apiFilters += `OrderBy=${encodeURIComponent(filters.sorter.column)}&OrderDesc=${filters.sorter.direction === "desc" ? 1 : 0}`
    }

    // Handling pagination parameters
    if (filters.Limit !== undefined && usePagination) {
      apiFilters += `&Limit=${encodeURIComponent(filters.Limit)}`
    }
    if (filters.Offset !== undefined && usePagination) {
      apiFilters += `&Offset=${encodeURIComponent(filters.Offset)}`
    }

    return apiFilters
  }

  const getFiltersUrl = () => {
    // Parsing URL filters
    parseUrlFilters()

    // Building API filters
    const apiFilters = buildApiFilters()

    return new URLSearchParams(apiFilters)
  }

  // Function to fetch data from the API
  const fetchData = async (checkCache = true) => {
    // Showing loader before fetching data
    $showLoader()

    // Parsing URL filters
    parseUrlFilters()

    // Building API filters
    const apiFilters = buildApiFilters()

    try {
      cacheStore.cleanExpiredResponses()

      const apiRequestUrl = `${urlRef.value.split("?")[0]}?${new URLSearchParams(apiFilters)}`

      const cacheResponse = cacheStore.getResponseByUrl(apiRequestUrl)
      if (cacheResponse && checkCache) {
        data.value = {
          table: cacheResponse?.response?.[tablekey],
          total: cacheResponse?.total
        }
      } else {
        if (checkCache) cacheStore.removeResponse(apiRequestUrl)

        // Making an API request using the 'request' function from 'useAxios'
        const { data: axiosResponse } = await request({
          url: apiRequestUrl,
          method: METHODS.GET
        })

        // Extracting relevant table data and total count from the API response
        data.value = {
          table: axiosResponse?.response?.[tablekey],
          total: axiosResponse?.total
        }

        cacheStore.setResponse(apiRequestUrl, axiosResponse)
      }
    } catch (error) {
      // Handling API errors and setting the error state
      error.value = error?.response
    } finally {
      // Closing loader after fetching data
      $closeLoader()
    }
  }

  return {
    // States
    data,
    error,

    // Functions
    getFiltersUrl,
    fetchData
  }
}
