import { ref } from "vue"

import { useAxios } from "./useAxios"
import { usePlugins } from "./usePlugins"

import { METHODS } from "@/lib/constants"

// Composable to manage modals
export const useModal = () => {
  // Define reactive variables for modal visibility and form data
  const visible = ref(false)
  const editFormData = ref(null)

  // Import axios request and plugin utilities
  const { request } = useAxios()
  const { $showLoader, $closeLoader } = usePlugins()

  // Function to open a modal, optionally fetching data from a URL
  const openModal = url => {
    setTimeout(async () => {
      if (!url) {
        // If no URL is provided, simply set modal visibility to true
        visible.value = true
        return
      }
      try {
        // Show loader while fetching data
        $showLoader()
        // Make axios request to fetch data
        const { data } = await request({ method: METHODS.GET, url })
        // Set fetched data to editFormData
        editFormData.value = data?.response

        // Set modal visibility to true
        visible.value = true
      } finally {
        // Close loader after request completes
        $closeLoader()
      }
    }, 1)
  }

  // Function to close the modal
  const closeModal = () => {
    setTimeout(() => {
      // Reset State
      editFormData.value = null
      visible.value = false
    }, 1)
  }

  // Return reactive variables and functions to be used in components
  return {
    // States
    visible,
    editFormData,

    // Functions
    openModal,
    closeModal
  }
}
