import { ROUTE_NAMES, ROUTE_PATHS } from "@/lib/constants"
import { tc } from "@/lib/services"

export default [
  // 404 Error page
  {
    name: ROUTE_NAMES.ERRORS.NOT_FOUND,
    path: ROUTE_PATHS.ERRORS.NOT_FOUND.BASE,
    component: () => import(/* webpackChunkName: "error-404" */ "./404/Index.vue"),
    meta: {
      title: tc("PageTitle.NotFound")
    }
  },

  // 403 Error Page
  {
    name: ROUTE_NAMES.ERRORS.FORBIDDEN,
    path: ROUTE_PATHS.ERRORS.FORBIDDEN,
    component: () => import(/* webpackChunkName: "error-403" */ "./403/Index.vue"),
    meta: {
      title: tc("PageTitle.Forbidden")
    }
  }
]