import { useAxios } from "./useAxios"
import { usePlugins } from "./usePlugins"

import { METHODS } from "@/lib/constants"
import { array, tc } from "@/lib/services"

export const useCrud = (url, updateDataProviderFunction = null) => {
  const { request } = useAxios()
  const { $showLoader, $closeLoader, $toast } = usePlugins()

  // Generic function to make requests
  const makeRequest = async ({
    method,
    endpoint,
    data = null,
    title,
    message
  }, successCallback) => {
    try {
      // Show loader before making the request.
      $showLoader()

      // Make the request using Axios.
      await request({
        url: endpoint,
        method: method,
        data: data
      })

      // Show toast notification for successful operation.
      $toast({ title, message })

      if (successCallback) successCallback()

      if (updateDataProviderFunction) updateDataProviderFunction()
    } catch (error) {
      const messages = error?.response?.data?.messages

      // Show error toast if error exists
      if (messages) {
        $toast({
          title: title,
          message: array.toString(messages),
          type: "danger",
          timeout: 4000
        })
      }
    } finally {
      $closeLoader()
    }
  }

  // Function to make a create request.
  const createRequest = async (payload, successCallback) => {
    await makeRequest({
      method: METHODS.POST,
      endpoint: url,
      data: payload,
      title: tc("General.CreateItem"),
      message: tc("General.CreateItemSuccess")
    }, () => successCallback())
  }

  // Function to make an edit request.
  const editRequest = async (id, payload, successCallback) => {
    await makeRequest({
      method: METHODS.PUT,
      endpoint: !id ? url : `${url}/${id}`,
      data: payload,
      title: tc("General.EditItem"),
      message: tc("General.EditItemSuccess")
    }, () => successCallback())
  }

  // Function to delete an item by its ID from the provided URL.
  const deleteRequest = async id => {
    await makeRequest({
      method: METHODS.DELETE,
      endpoint: `${url}/${id}`,
      title: tc("General.DeleteItem"),
      message: tc("General.DeleteItemSuccess")
    })
  }

  // Function to get an item
  const getRequest = async () => {
    try {
      $showLoader()

      // Make the request using Axios.
      const { data } = await request({ url, method: METHODS.GET })

      if (!data?.response) {
        throw new Error("No data on response")
      }

      return data?.response
    } catch (error) {
      console.error(error)
    } finally {
      $closeLoader()
    }
  }

  return {
    // Functions
    getRequest,
    createRequest,
    editRequest,
    deleteRequest
  }
}
