import { AppPdiMapPopup, AppTooltipMapPopup } from "../components"

import { MAP } from "@/lib/constants"
import { createVueComponent } from "@/lib/services"

class MapPopupManager {
  constructor() {
    // All Available popups
    this.popupComponents = {
      // PDIS
      [MAP.POPUP_VARIANT.PDI]: AppPdiMapPopup,

      // Tooltip
      [MAP.POPUP_VARIANT.TOOLTIP]: AppTooltipMapPopup
    }
  }

  // Function to get the component based on its type
  getComponent(type) {
    return this.popupComponents[type] || null
  }

  // Function to handle marker popups
  handleMarkerPopup({ data, marker }) {
    const popupComponent = this.getComponent(data?.type)

    // If no popup component found, exit
    if (!popupComponent) return

    const popupContent = createVueComponent(popupComponent, { data })

    marker.bindPopup(popupContent, {
      maxWidth: 262
    })
  }
}

export const mapPopupManager = new MapPopupManager()