export const ENTITY_TYPES = {
  ADMIN: "admin",
  PROVIDER: "provider"
}

// Retrieve the current entity type from environment variables
export const APP_ENTITY = process.env.VUE_APP_ENTITY

// Check if the current entity type is admin
export const IS_ADMIN_ENTITY = APP_ENTITY === ENTITY_TYPES.ADMIN

// Check if the current entity type is providers
export const IS_PROVIDER_ENTITY = APP_ENTITY === ENTITY_TYPES.PROVIDER

Object.freeze(ENTITY_TYPES)
Object.freeze(APP_ENTITY)
Object.freeze(IS_ADMIN_ENTITY)
Object.freeze(IS_PROVIDER_ENTITY)