export const MAP = {
  // Options
  URL_TEMPLATE: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",

  // Locations
  LOCATIONS: {
    SPAIN: [40.416775, -3.70379],
    VALLADOLID: [41.652251, -4.724532]
  },

  POPUP_VARIANT: {
    PDI: "pdi",
    TOOLTIP: "tooltip"
  },

  // Icon Variants
  ICON_VARIANTS: {
    DESTINY: "destiny",
    FINISHED_ROUTE: "finished-route",
    IN_PROGRESS_ROUTE: "in-progress-route",
    LOADING: "loading",
    LOCATION: "location",
    ORIGIN: "origin",
    PDI_ROUTE: "pdi-route",
    ROUTE_IN_SQUARE: "route-in-square",
    STOPPED_ROUTE: "stopped-route",
    TRUCK: "truck",
    UNSTARTED_ROUTE: "unstarted-route",
    WORKER: "worker",
    DRIVER: "driver"
  },

  // Marker Images
  IMAGES: {
    DESTINY: "/images/map/destiny.svg",
    FINISHED_ROUTE: "/images/map/finished-route.svg",
    IN_PROGRESS_ROUTE: "/images/map/in-progress-route.svg",
    LOADING: "/images/map/loading.svg",
    LOCATION: "/images/map/location.svg",
    DOT: "/images/map/dot.svg",
    ORIGIN: "/images/map/origin.svg",
    PDI_ROUTE: "/images/map/pdi-route.svg",
    ROUTE_IN_SQUARE: "/images/map/route-in-square.svg",
    STOPPED_ROUTE: "/images/map/stopped-route.svg",
    TRUCK: "/images/map/truck.svg",
    UNSTARTED_ROUTE: "/images/map/unstarted-route.svg",
    WORKER: "/images/map/worker.svg"
  }
}

Object.freeze(MAP)